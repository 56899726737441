import Analytics from "../../../lib/analytics/analytics";
import LocaleLink from "../../Navigation/LocaleLink";
import { OnDisplayResultsFunction } from "./SearchBarTypes";
import React from "react";
import Searchbar from "./SearchBar";
import { TheCycleverseTheme } from "src/theme";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

const welcomeHeightVH = 75;
const welcomeTextOffsetVHMobile = 12;
const welcomeTextOffsetVH = 20;

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  productSearchBarHightlight: {
    minHeight: `${welcomeHeightVH}vh`,
    position: "relative",
    overflowX: "hidden",
  },
  placeholder: {
    height: 100,
    width: "100%",
    bottom: 0,
    position: "absolute",
  },
  stickybar: {
    transition: "margin-top 0.33s ease-in",
    display: "block",
    position: "relative",
  },
  hidden: {
    marginTop: -100,
    display: "none",
  },
  productSearchbarContainer: {
    overflow: "hidden",
  },
  welcomeBackground: {
    position: "absolute",
    height: "75vh",
    width: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "#000",
    backgroundImage: "url('/images/searchBgDesktop2021.jpg')",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: "url('/images/searchBgMobile2021.jpg')",
    },
    [theme.breakpoints.down("xs")]: {
      backgroundImage: "url('/images/searchBgMobile2021.jpg')",
    },
    [theme.breakpoints.up("xl")]: {
      backgroundImage: "url('/images/searchBgDesktopLarge2021.jpg')",
    },
  },
  welcomeText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: `${welcomeTextOffsetVH}vh`,
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: "center",
    width: "100vw",
    position: "relative",
    top: 0,
    left: 0,
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      paddingTop: `${welcomeTextOffsetVHMobile}vh`,
      width: "100vh",
      maxWidth: "100%",
    },
  },
  title: {
    fontSize: 36,
    fontWeight: 900,
    lineHeight: 1.1,
    marginBottom: theme.spacing(4),
    maxWidth: "648px",
    "& span": {
      display: "inline-block",
      whiteSpace: "pre-wrap",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
      paddingTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  searchBarSmall: {
    display: "inline-block",
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
      minWidth: 400,
      "& .search-bar": {
        maxWidth: 400,
        display: "inline-block",
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "& .FX-recommendations-item": {},
  },
  searchBarSearch: {
    zIndex: 5,
    border: "double 1px transparent",
    backgroundImage: `linear-gradient(white, white), ${theme.highlightGradient}`,
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
  },
  subTextLink: {
    textDecoration: "underline",
  },
}));

type ProductSearchBarHightlight = {
  onDisplayResults?: OnDisplayResultsFunction;
  className?: string;
};

const ProductSearchBarHightlight = (props: ProductSearchBarHightlight) => {
  const { onDisplayResults, className } = props;
  const classes = useStyles();
  const { t, lang: locale } = useTranslation("common");
  const isDesktopView = useMediaQuery((theme: TheCycleverseTheme) => theme.breakpoints.up("sm"));
  const router = useRouter();

  const search = (value) => {
    if (value) {
      const dest = {
        pathname: "/products/search",
        query: {
          s: value,
        },
      };
      router.push(dest, dest, { locale });
    }
  };

  return (
    <>
      <div
        className={`${classes.productSearchBarHightlight} ${className ? className : ""}`}
        data-test="searchbar-highlight"
      >
        <div className={`${classes.welcomeBackground}`} />
        <div className={`${classes.welcomeText}`}>
          {!isDesktopView ? (
            <div>
              <h1 className={classes.title}>
                <span>{t("productsTitleMobile1")}</span>
                <span>{t("productsTitleMobile2")}</span>
                <span>{t("productsTitleMobile3")}</span>
              </h1>
            </div>
          ) : (
            <div>
              <h1 className={classes.title}>
                <span>{t("productsTitleDesktop1")}</span>
                <span>{t("productsTitleDesktop2")}</span>
              </h1>
            </div>
          )}
          <Searchbar
            containerClassName={classes.searchBarSmall}
            searchClassName={classes.searchBarSearch}
            placeholder={t("productSearchBarPlaceholder")}
            onSearch={(value) => search(value)}
            recommendationType="products"
            variant="secondary"
            searchType="highlight"
            scrollTo="input"
            subText={
              <span>
                {t("productSearchBarSubText1")}
                <LocaleLink notStyled href="/e-bikes" as="/e-bikes" aClass={classes.subTextLink}>
                  {t("productSearchBarSubText1_1")}
                </LocaleLink>
                {", "}
                <LocaleLink notStyled href="/gravel" as="/gravel" aClass={classes.subTextLink}>
                  {t("productSearchBarSubText1_2")}
                </LocaleLink>
                {", "}
                <LocaleLink notStyled href="/mtb-schuhe" as="/mtb-schuhe" aClass={classes.subTextLink}>
                  {t("productSearchBarSubText1_3")}
                </LocaleLink>
                {", "}
                {t("productSearchBarSubText2")}
              </span>
            }
            onDisplayResults={(value) => onDisplayResults && onDisplayResults(value)}
          />
        </div>
      </div>
    </>
  );
};

export default ProductSearchBarHightlight;
