import React from "react";
import { Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    display: "inline-block",
  },
}));

const CategoryIcon = (props) => {
  const { img, className } = props;
  const classes = useStyles();

  return (
    <Icon style={{ backgroundImage: `url(${img})` }} className={` ${classes.root} ${className ? className : ""}`} />
  );
};

export default CategoryIcon;
