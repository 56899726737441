import { Icon } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = (props: { closed: boolean }) =>
  makeStyles(() => ({
    root: {
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      display: "block",
      backgroundImage: `url("${props.closed ? "/images/navmenu/Burger-Close.svg" : "/images/navmenu/Burger.svg"}")`,
    },
  }))();

export type BurgerIconProps = {
  closed: boolean;
};

const BurgerIcon = (props: BurgerIconProps) => {
  const { closed } = props;
  const classes = useStyles({ closed });

  return <Icon className={classes.root} />;
};

export default BurgerIcon;
