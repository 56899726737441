import { LayoutSearchBar, LayoutVariant } from "../Layout/LayoutTypes";
import React, { FC, ReactElement, useEffect, useRef, useState } from "react";

import Analytics from "../../lib/analytics/analytics";
import AppBar from "@material-ui/core/AppBar";
import BurgerIcon from "./Icons/BurgerIcon";
import IconButton from "@material-ui/core/IconButton";
import LocaleLink from "./LocaleLink";
import NavMenu from "./NavMenu";
import ProductSearchBar from "../Content/SearchBar/ProductSearchBar";
import ProductSearchBarHighlight from "../Content/SearchBar/ProductSearchBarHighlight";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { TheCycleverseTheme } from "src/theme";
import Toolbar from "@material-ui/core/Toolbar";
import VisibilitySensor from "react-visibility-sensor";
import environment from "../../environment";
import { makeStyles } from "@material-ui/core/styles";
import { useClickAway } from "react-use";
import { useMediaQuery } from "@material-ui/core";

const iconSize = 30;

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    height: theme.menuBarHeight,
    [theme.breakpoints.up("sm")]: {
      // paddingLeft: theme.globalMarginDesktop,
      // paddingRight: theme.globalMarginDesktop,
      "& .MuiToolbar-gutters": {
        paddingLeft: theme.globalMarginDesktop,
        paddingRight: theme.globalMarginDesktop,
      },
    },
    backgroundColor: environment.name === "dev" ? "#963131" : "#000",
  },
  appbarSmall: {
    top: 100,
  },
  saleButton: {
    "& .MuiButton-label": {
      color: theme.saleColor,
    },
  },
  menuDrawerButton: {
    textTransform: "none",
    fontSize: 18,
    fontWeight: 600,
    width: "100%",
    "& .MuiButton-label": {
      textAlign: "left",
      display: "block",
    },
  },
  menuDrawerButtonActive: {
    // color: theme.unflashyColor,
    // '& .MuiButton-label': {
    //   borderBottom: `2px solid`
    // }
  },
  logo: {
    width: "100%",
    height: "100%",
    backgroundImage: "url(/images/TheCycleverse-Logo2021.svg)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  logoContainer: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    height: 45,
    [theme.breakpoints.down("xs")]: {
      height: 35,
    },
    [theme.breakpoints.up("md")]: {
      marginRight: iconSize,
    },
  },
  logoNoLink: {
    display: "inline-block",
    height: 60,
    width: 250,
    backgroundImage: "url(/images/Logo.svg)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  accountButton: {
    minWidth: "36px",
  },
  iconplaceholder: {
    height: iconSize,
    width: iconSize,
  },
  sideDrawer: {
    "& .MuiDrawer-paperAnchorLeft": {
      overflowX: "hidden",
    },
    "& .MuiDrawer-paper.MuiDrawer-paperAnchorLeft": {
      width: 700,
      maxWidth: "100%",
    },
  },
  sideDrawerTopPaddingAppbar: {
    "& .MuiDrawer-paper": {
      zIndex: 5000,
    },
    "& .MuiDrawer-root": {
      top: `${theme.menuBarHeight}px !important`,
    },
    "& .MuiDrawer-paper.MuiDrawer-paperAnchorLeft": {
      marginTop: theme.menuBarHeight,
    },
    "& .MuiBackdrop-root": {
      marginTop: theme.menuBarHeight,
    },
  },
  sideDrawerTopPaddingSearchbar: {
    "&.MuiDrawer-root": {
      top: `${theme.searchBarHeight}px !important`,
    },
    "& .MuiDrawer-paper.MuiDrawer-paperAnchorLeft": {
      marginTop: theme.searchBarHeight,
    },
    "& .MuiBackdrop-root": {
      marginTop: theme.searchBarHeight,
    },
  },
  drawerList: {
    minWidth: 300,
  },
  sticky: {
    position: "sticky",
    top: 0,
    zIndex: 5,
  },
  divider: {
    ...theme.searchDivider,
  },
}));

export type LayoutProps = {
  variant?: LayoutVariant;
  searchBar?: LayoutSearchBar;
  menuComponent?: ReactElement | FC;
  notificationComponent?: ReactElement | FC;
};

const Header: FC<LayoutProps> = ({ variant, searchBar, menuComponent, notificationComponent }) => {
  const classes = useStyles();
  const [drawer, setDrawer] = useState(false);
  const [appbarVisible, setAppbarVisible] = useState(true);
  const [hasSearchContent, setHasSearchContent] = useState(false);
  const isDesktopView = useMediaQuery((theme: TheCycleverseTheme) => theme.breakpoints.up("sm"));
  const navMenuRef = useRef(null);
  var clickAwayTimer = null;

  const toggleDrawer = (open: boolean) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    clickAwayTimer && clearTimeout(clickAwayTimer);
    setDrawer(open);
    if (open) {
      Analytics.menuOpen();
    }
  };

  useClickAway(navMenuRef, () => {
    clickAwayTimer && clearTimeout(clickAwayTimer);
    clickAwayTimer = setTimeout(() => {
      setDrawer(false);
    }, 200);
  });

  useEffect(() => {
    const bodyTag = document.getElementsByTagName("BODY")[0] as HTMLElement;

    if (drawer) {
      bodyTag.style.overflow = "hidden";
    } else {
      bodyTag.style.overflow = "auto";
    }

    return function cleanup() {
      bodyTag.style.overflow = "auto";
    };
  });

  const BurgerButton: FC = () => {
    return (
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(!drawer)}
        data-test="navmenu-toggle"
      >
        <BurgerIcon closed={!!drawer} />
      </IconButton>
    );
  };

  if (variant === "partner") {
    return (
      <div className={classes.root}>
        <AppBar className={classes.appbar} elevation={0} position="static" color="secondary">
          <div style={{ textAlign: "center" }}>
            <div className={classes.logoNoLink}></div>
          </div>
        </AppBar>
      </div>
    );
  }

  return (
    <>
      <div key="header" className={classes.root} data-test="header-main">
        <VisibilitySensor delayedCall={true} partialVisibility={true} onChange={(value) => setAppbarVisible(value)}>
          <AppBar
            className={classes.appbar}
            elevation={0}
            position="relative"
            style={{
              zIndex: 1000,
            }}
            color="secondary"
          >
            <Toolbar>
              <BurgerButton />

              <LocaleLink notStyled as="/" href="/" aClass={classes.logoContainer}>
                <div className={classes.logo} data-test="navmenu-logo" />
              </LocaleLink>

              <div className={classes.iconplaceholder} />
            </Toolbar>
          </AppBar>
        </VisibilitySensor>
        {drawer && (
          <SwipeableDrawer
            variant="persistent"
            open={drawer}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            className={`${classes.sideDrawer} ${
              appbarVisible ? classes.sideDrawerTopPaddingAppbar : classes.sideDrawerTopPaddingSearchbar
            }`}
          >
            <div className={classes.drawerList} ref={navMenuRef}>
              <NavMenu isHidden={!drawer} onSelect={() => setDrawer(false)} />
            </div>
          </SwipeableDrawer>
        )}
        {notificationComponent && notificationComponent}
      </div>

      {"productStickyHighlight" == searchBar && (
        <ProductSearchBarHighlight onDisplayResults={(value) => setHasSearchContent(value)} />
      )}

      {"productSticky" == searchBar && (
        <>
          <ProductSearchBar
            className={hasSearchContent ? "" : classes.sticky}
            iconButtonLeft={appbarVisible || hasSearchContent ? undefined : <BurgerButton />}
            searchBarPlaceholderTextKey="productSearchBarPlaceholder"
            recommendationType="products"
            onDisplayResults={(value) => setHasSearchContent(value)}
          />
        </>
      )}

      {"blogSticky" == searchBar &&
        (isDesktopView ? (
          <div className={`${hasSearchContent ? "" : classes.sticky}`}>
            <ProductSearchBar
              iconButtonLeft={appbarVisible || hasSearchContent ? undefined : <BurgerButton />}
              searchBarPlaceholderTextKey="articleSearchBarPlaceholder"
              recommendationType="articles"
              searchBarDividerVariant={menuComponent ? "secondary" : "primary"}
              onDisplayResults={(value) => setHasSearchContent(value)}
            />
            {menuComponent && (
              <>
                {menuComponent}
                <div className={classes.divider} />
              </>
            )}
          </div>
        ) : (
          <>
            <ProductSearchBar
              className={hasSearchContent ? "" : classes.sticky}
              iconButtonLeft={appbarVisible || hasSearchContent ? undefined : <BurgerButton />}
              searchBarPlaceholderTextKey="articleSearchBarPlaceholder"
              recommendationType="articles"
              onDisplayResults={(value) => setHasSearchContent(value)}
            />
            {menuComponent && menuComponent}
          </>
        ))}

      {("none" == searchBar || !searchBar) && <>{/*don't display a search*/}</>}
    </>
  );
};

export default Header;
