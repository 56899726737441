import React, { FC, ReactElement } from "react";

import { TheCycleverseTheme } from "src/theme";
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  footerSegment: {
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  headline: {
    fontSize: "medium",
    fontWeight: 500,
    margin: "9px 0px",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.footerLinkOffet,
    },
  },
}));

export type FooterSegmentProps = {
  headline: string;
  content: any;
  classNameHeadline?: string;
};

const FooterSegment: FC<FooterSegmentProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.footerSegment}>
      <div className={clsx(classes.headline, props.classNameHeadline)}>{props.headline}</div>
      {props.content}
    </div>
  );
};

export default FooterSegment;
