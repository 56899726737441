import { makeStyles, useMediaQuery } from "@material-ui/core";

import { SearchBarColumnContainer } from "./SearchBarCardComponents";
import { TheCycleverseTheme } from "src/theme";
import useTranslation from "next-translate/useTranslation";

const useStylesRecommendations = makeStyles((theme: TheCycleverseTheme) => ({
  divider: {
    height: 1,
    backgroundColor: theme.unflashyColor,
    marginLeft: 20,
    marginRight: 20,
  },
  item: {
    margin: "0px",
    fontSize: "initial",
    cursor: "pointer",
    padding: "2.5px 0px",
    width: "100%",
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    alignContent: "center",
    "&:hover": {
      backgroundColor: theme.unflashyerColor,
    },
  },
  notDesktopView: {
    padding: "5px 0px",
  },
  recommendationTitle: {
    color: theme.saleColor,
  },
}));

type SearchBarRecommendationsTermType = {
  recommendedTerms: Array<string>;
  searchValue: string;
  onSelect?: Function;
};

const SearchBarRecommendationsTerm = (props: SearchBarRecommendationsTermType) => {
  const { recommendedTerms, onSelect = () => {}, searchValue } = props;
  const { t } = useTranslation("common");
  const classes = useStylesRecommendations();
  const isDesktopView = useMediaQuery((theme: TheCycleverseTheme) => theme.breakpoints.up("sm"));
  const terms = [searchValue, ...recommendedTerms];

  return (
    <SearchBarColumnContainer
      desktopViewHasTitle={false}
      title={t("searchedRecommendationsTitle")}
      titleClassName={classes.recommendationTitle}
    >
      {terms ? (
        terms.map((value, index) => {
          return (
            <li
              data-test="SearchBarRecommendationTerm"
              className={`FX-recommendations-item ${classes.item} ${isDesktopView ? "" : classes.notDesktopView}`}
              key={index}
              onClick={() => {
                onSelect(value);
              }}
            >
              {value}
            </li>
          );
        })
      ) : (
        <img src="/images/Oh-no.svg" height={40} width={40} alt="Oh no!" />
      )}
    </SearchBarColumnContainer>
  );
};

export default SearchBarRecommendationsTerm;
