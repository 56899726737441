import { Grid, Typography, makeStyles } from "@material-ui/core";

import Analytics from "src/lib/analytics/analytics";
import LocaleLink from "src/components/Navigation/LocaleLink";
import { SearchBarColumnContainer } from "./SearchBarCardComponents";
import { TheCycleverseTheme } from "src/theme";
import useTranslation from "next-translate/useTranslation";

const useStylesRecommendations = makeStyles((theme: TheCycleverseTheme) => ({
  item: {
    margin: "0px",
    fontSize: "initial",
    cursor: "pointer",
    marginBottom: "3px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.unflashyerColor,
    },
    minHeight: "60px",
  },
  articleTitleText: {
    display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    height: "3.2em",
    overflowWrap: "break-word",

    [theme.breakpoints.up("md")]: {
      fontSize: "0.90rem",
      whiteSpace: "normal",
      display: "inline-block",
    },
  },
  articleTitle: {
    color: theme.searchBarArticleColor,
  },
  itemImage: {
    width: "60px",
    maxHeight: "60px",
    marginRight: "10px",
    "object-fit": "contain",
    [theme.breakpoints.down(400)]: {
      width: "50px",
      maxHeight: "50px",
      marginRight: "10px",
    },
  },
}));

type SearchBarRecommendationsArticleType = {
  articles: Array<any>;
  searchValue: string;
  onSearchMore?: Function;
};

const SearchBarRecommendationsArticle = (props: SearchBarRecommendationsArticleType) => {
  const { articles, searchValue, onSearchMore = () => {} } = props;
  const { t } = useTranslation("common");
  const classes = useStylesRecommendations();

  const onClick = (article) => {
    Analytics.articleClick(article.id, {
      author: article.author,
      category: article.category,
    });
  };

  return (
    <SearchBarColumnContainer
      title={t("searchedArticlesTitle")}
      findMoreText={t("showAllResult")}
      findMorePath={`/blog/search?s=${searchValue}`}
      titleClassName={classes.articleTitle}
      onSearchMore={onSearchMore}
    >
      {articles.map((article, index) => (
        <LocaleLink
          key={index}
          notStyled
          href="/blog/[generic]"
          as={`/blog/${article.id}`}
          onClick={() => onClick(article)}
        >
          <li
            data-test="SearchBarRecommendationArticle"
            className={`FX-recommendations-item ${classes.item}`}
            key={index}
          >
            <Grid item>
              <img src={`${article.images[0].uri}-85`} className={classes.itemImage} alt={article.images[0]?.alt} />
            </Grid>
            <Grid item>
              <Typography className={classes.articleTitleText}>{article.title}</Typography>
            </Grid>
          </li>
        </LocaleLink>
      ))}
    </SearchBarColumnContainer>
  );
};

export default SearchBarRecommendationsArticle;
