import { Children, FC } from "react";
import { Grid, makeStyles, useMediaQuery } from "@material-ui/core";

import LocaleLink from "src/components/Navigation/LocaleLink";
import { TheCycleverseTheme } from "src/theme";

const useStylesRecommendations = makeStyles((theme: TheCycleverseTheme) => ({
  showAllLink: {
    color: "inherit",
    marginBottom: "20px",
  },
}));

type SearchRecommendationsTypes = {
  text: string;
  path: string;
  onClick: Function;
};

export const SearchRecommendationsFindMore: FC<SearchRecommendationsTypes> = ({ text, path, onClick }) => {
  const classes = useStylesRecommendations();

  return (
    <div className={classes.showAllLink}>
      <LocaleLink aClass={classes.showAllLink} href={path} as={path} onClick={onClick}>
        {text}
      </LocaleLink>
    </div>
  );
};

const useStylesRecommendationsTitle = makeStyles((theme: TheCycleverseTheme) => ({
  rowTitle: {
    marginBottom: "10px",
    fontSize: "initial",
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
  title: {
    padding: "5px 0px",
  },
  articleTitle: {
    color: theme.searchBarArticleColor,
    padding: "5px 0px",
  },
}));

type SearchBarColumnTitle = {
  title: string;
  className: any;
};

export const SearchBarColumnTitle: FC<SearchBarColumnTitle> = ({ title, className }) => {
  const classes = useStylesRecommendationsTitle();
  return (
    <Grid item className={`${classes.rowTitle}`} xs={12}>
      <span className={`${classes.title} ${className || classes.articleTitle}`}>{title}</span>
    </Grid>
  );
};

export const SearchBarRecommendationsNotFound: FC = () => (
  <img src="/images/Oh-no.svg" height={40} width={40} alt="Oh no!" data-test="SearchResultNotFoundImage" />
);

const useStylesRecommendationsContainer = makeStyles((theme: TheCycleverseTheme) => ({
  recommendations: { height: "100%" },
  list: {
    listStyleType: "none",
    paddingInlineStart: 0,
    textAlign: "left",
    marginTop: 0,
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
  },
}));

type SearchBarColumnContainer = {
  titleClassName?: string;
  title?: string;
  findMoreText?: string;
  findMorePath?: string;
  desktopViewHasTitle?: boolean;
  onSearchMore?: Function;
};

export const SearchBarColumnContainer: FC<SearchBarColumnContainer> = ({
  titleClassName,
  title,
  children,
  findMoreText,
  findMorePath,
  desktopViewHasTitle = true,
  onSearchMore,
}) => {
  const classes = useStylesRecommendationsContainer();
  const isDesktopView = useMediaQuery((theme: TheCycleverseTheme) => theme.breakpoints.up("sm"));
  const childrenAvailable = children && Children.count(children) > 0;
  return (
    <div className={`FX-recommendations ${classes.recommendations}`}>
      {!isDesktopView && !desktopViewHasTitle ? null : (
        <SearchBarColumnTitle className={titleClassName} title={title} />
      )}
      <ul className={`FX-recommendations-list ${classes.list} ${classes.cardContainer}`}>
        {childrenAvailable ? <>{children}</> : <SearchBarRecommendationsNotFound />}
      </ul>
      {childrenAvailable && findMoreText && findMorePath ? (
        <SearchRecommendationsFindMore
          text={findMoreText}
          path={findMorePath}
          onClick={() => onSearchMore && onSearchMore()}
        />
      ) : (
        <div />
      )}
    </div>
  );
};
