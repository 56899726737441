import { useContext, useEffect, useState } from "react";

import AdContext from "../Ads/AdContext";
import Analytics from "src/lib/analytics/analytics";
import Button from "@material-ui/core/Button";
import ButtonLink from "../Navigation/ButtonLink";
import Checkbox from "@material-ui/core/Checkbox";
import { ConsentStorage } from "./ConsentStorage";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Modal from "@material-ui/core/Modal";
import Script from "next/script";
import environment from "src/environment";
import { makeStyles } from "@material-ui/core/styles";
import { useEffectOnce } from "react-use";
import useTranslation from "next-translate/useTranslation";

function getModalStyle() {
  const top = 40;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-50%, -50%)`,
    outline: "none",
  };
}

const useStyles = makeStyles((theme) => ({
  cookieConsent: {
    position: "absolute",
    minWidth: 280,
    maxWidth: 380,
    maxHeight: "85%",
    display: "block",
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: 0,
    backgroundColor: "#fff",
    textAlign: "center",
    borderRadius: theme.shape.borderRadius,
  },
  logo: {
    marginBottom: theme.spacing(1),
  },
  title: {
    fontSize: "x-large",
    fontFamily: theme.highlightFontFamily,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  text: {
    marginBottom: theme.spacing(1),
  },
  checkboxes: {
    marginBottom: theme.spacing(2),
  },
  checkboxRow: {
    textAlign: "left",
    display: "inline-block",
  },
  constentButton: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  constentButtonAccept: {
    color: "#fff",
    backgroundColor: "#28a745",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#28a745d9",
    },
  },
  constentButtonSave: {
    // backgroundColor: "#eee"
    border: "1px solid #eee",
  },
  footer: {
    "& .MuiButton-label": {
      color: "#aaa",
      fontWeight: 300,
    },
  },
  infoButton: {
    fontSize: "smaller",
  },
}));

const CookieConsent = () => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  var consentInternalGiven = ConsentStorage.getItemAsBool("internal") ? true : false;
  var consentExternalGiven = ConsentStorage.getItemAsBool("external") ? ConsentStorage.getItemAsBool("external") : true;
  const [open, setOpen] = useState(!consentInternalGiven);
  const [externalCookiesAllowed, setExternalCookiesAllowed] = useState(consentExternalGiven);
  const [userId, setUserId] = useState();
  const [thirdPartyScriptsAllowed, setThirdPartyScriptsAllowed] = useState(
    ConsentStorage.getItemAsBool("external") || false
  );
  const { adsEnabled, setAdsEnabled } = useContext(AdContext);
  const { t } = useTranslation("common");

  useEffect(() => {
    if (setAdsEnabled && adsEnabled != thirdPartyScriptsAllowed) {
      setAdsEnabled(thirdPartyScriptsAllowed);
    }
  }, [adsEnabled, setAdsEnabled, thirdPartyScriptsAllowed]);

  useEffectOnce(async () => {
    const userId = await Analytics.getUserId();
    setUserId(userId);
  });

  const handleClose = () => {
    setOpen(false);
  };

  const setConsents = (consentInternal, consentExternal) => {
    ConsentStorage.setItem("internal", consentInternal);
    ConsentStorage.setItem("external", consentExternal);
    setThirdPartyScriptsAllowed(consentExternal);
    if (consentExternal) {
      Analytics.cookieAccept();
    } else {
      Analytics.cookieDecline();
    }
  };

  const handleSave = () => {
    setConsents(true, externalCookiesAllowed);
    handleClose();
  };

  const handleAcceptAll = () => {
    setConsents(true, true);
    handleClose();
  };

  return (
    <>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        disableScrollLock
        disableEscapeKeyDown
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            onClose(event, reason);
          }
        }}
      >
        <div style={modalStyle} className={classes.cookieConsent} data-test="cookieconsent-banner">
          <div className={classes.logo}>
            <img loading="lazy" src="/images/Logo-Pop-Up.svg" width="35px" height="35px" alt="cookie-banner-logo" />
          </div>
          <div className={classes.title}>{t("cookieConsentTitle")}</div>
          <div className={classes.text}>{t("cookieConsentText")}</div>
          <div className={classes.checkboxes}>
            <FormGroup row className={classes.checkboxRow}>
              <FormControlLabel
                control={<Checkbox disabled checked inputProps={{ "aria-label": "disabled checked checkbox" }} />}
                label={t("cookieConsentCheckboxEssential")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={externalCookiesAllowed}
                    onChange={(e) => {
                      setExternalCookiesAllowed(e.target.checked);
                    }}
                    inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                    data-test="cookieconsent-external-checkbox"
                  />
                }
                label={t("cookieConsentCheckboxExternal")}
              />
            </FormGroup>
          </div>
          <div>
            <Button
              className={`${classes.constentButton} ${classes.constentButtonAccept}`}
              onClick={handleAcceptAll}
              data-test="cookieconsent-accept"
            >
              {t("cookieConsentAccept")}
            </Button>
            <Button
              className={`${classes.constentButton} ${classes.constentButtonSave}`}
              onClick={handleSave}
              data-test="cookieconsent-save"
            >
              {t("cookieConsentSave")}
            </Button>
          </div>
          <div className={classes.footer}>
            <ButtonLink buttonClassName={classes.infoButton} href="/privacy" as="/privacy" prefetch={false}>
              {t("footerPrivacy")}
            </ButtonLink>
            <ButtonLink buttonClassName={classes.infoButton} href="/contact" as="/contact" prefetch={false}>
              {t("footerContact")}
            </ButtonLink>
          </div>
        </div>
      </Modal>

      {thirdPartyScriptsAllowed && userId && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsId4}`}
            strategy="afterInteractive"
          />

          <Script id="google-analytics" strategy="afterInteractive">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){window.dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${environment.googleAnalyticsId4}', {
                'user_id': '${userId}',
                'send_page_view': false
              });
              `}
          </Script>

          {environment.name === "prod" && (
            <>
              <Script strategy="afterInteractive">
                {`
                  !function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', '${environment.facebookPixelId}', {'external_id': '${userId}'});
                  fbq('track', 'PageView');
                `}
              </Script>
              <noscript>
                <img
                  height="1"
                  width="1"
                  alt=""
                  style={{ display: "none" }}
                  src={`https://www.facebook.com/tr?id=${environment.facebookPixelId}&ev=PageView&noscript=1`}
                />
              </noscript>
              <Script
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: `
                    (function(h,o,t,j,a,r){
                      setTimeout(function(){
                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                        h._hjSettings={hjid:2257837,hjsv:6};
                        a=o.getElementsByTagName('head')[0];
                        r=o.createElement('script');r.async=1;
                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                        a.appendChild(r);
                      }, 5000)
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                  `,
                }}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default CookieConsent;
