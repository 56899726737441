import React, { useState } from "react";

import Analytics from "../../lib/analytics/analytics";
import CategoryIcon from "./Icons/CategoryIcon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import LocaleLink from "./LocaleLink";
import NavMenuCategorySlide from "./NavMenuCategorySlide";
import OpenIcon from "./Icons/OpenIcon";
import { TheCycleverseTheme } from "src/theme";
import { default as availableCategories } from "../../categoriesTree.json";
import { makeStyles } from "@material-ui/core/styles";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  navmenu: {
    "& .MuiListItemText-root .MuiTypography-root,.FX-NavMenu-ItemText": {
      fontSize: "medium",
      fontWeight: 400,
      marginTop: 0,
      marginBottom: 0,
    },
  },
  showAllItem: {
    "& .MuiListItemText-root .MuiTypography-root,.FX-NavMenu-ItemText": {
      color: theme.likeColor,
    },
  },
  linkButton: {
    flex: "1 1 auto",
  },
  expandButton: {
    width: 40,
    height: 40,
    padding: 14,
    "& .MuiIcon-root": {
      height: 12,
      width: 12,
    },
  },
  topLevelList: {},
  linkList: {
    margin: 0,
    padding: 0,
    paddingBottom: 300,
    "& .MuiTouchRipple-root": {
      borderBottom: `1px solid ${theme.unflashyerColor}`,
      marginLeft: theme.menuGutters,
      marginRight: theme.menuGutters,
    },
  },
  linkSubList: {
    marginLeft: 20,
  },
  menuItemIcon: {
    marginRight: 10,
    height: 40,
    width: 50,
    textAlign: "center",
    "& .MuiIcon-root": {
      height: 30,
      width: 30,
    },
  },
  bikeIcon: {
    width: "50px !important",
  },
  cyclinghacksItem: {
    "& .MuiListItemText-root": {
      color: theme.cyclinghacksColor,
    },
  },
  brandItem: {},
  saleItem: {
    "& .MuiListItemText-root": {
      color: theme.saleColor,
    },
  },
  journeysItem: {
    "& .MuiListItemText-root": {
      color: theme.journeysColor,
    },
  },
  adviceItem: {
    "& .MuiListItemText-root": {
      color: theme.adviceColor,
    },
  },
  hidden: {
    // visibility: 'hidden',
    maxHeight: 0,
    overflow: "hidden",
  },
  bikecompareText: {
    color: theme.bikeCompareColorMenu,
  },
}));

const NavMenu = (props) => {
  const { isHidden, onSelect } = props;
  const classes = useStyles();
  const { t } = useTranslation("common");
  const categoryTree = availableCategories;
  const [activePath, setActivePath] = useState("");
  const [prevPath, setPrevPath] = useState("");

  const buildMenuItem = (
    name: string,
    href: string,
    showExpandIcons: boolean,
    expandPath: string,
    icon?: any,
    listItemClass?: string,
    hrefAs?: string,
    skipTranslation?: boolean
  ) => {
    const displayNameKey = name.split(":")[0];
    const displayName = skipTranslation
      ? name
      : t(`categories:${displayNameKey}`, null, { fallback: `navmenu:${displayNameKey}` });
    if (showExpandIcons) {
      if (isHidden) {
        return (
          <li id={href} data-test="navmenu-menuItemHidden">
            <LocaleLink notStyled as={href} href={hrefAs ? hrefAs : "/[...generic]"}>
              <p>{displayName}</p>
            </LocaleLink>
          </li>
        );
      }
      return (
        <ListItem
          button
          key={displayName}
          className={`${listItemClass ? listItemClass : ""}`}
          onClick={(event) => {
            setPrevPath(activePath);
            setActivePath(expandPath);
            Analytics.menuNavigate(expandPath);
          }}
          data-test="navmenu-menuItemVisible"
        >
          {icon && <div className={classes.menuItemIcon}>{icon}</div>}
          <ListItemText
            disableTypography
            primary={
              <LocaleLink notStyled href={hrefAs ? hrefAs : "/[...generic]"} as={href} preventDefault={true}>
                <p className="FX-NavMenu-ItemText">{displayName}</p>
              </LocaleLink>
            }
          />
          <div className={classes.expandButton}>
            <OpenIcon fontSize="small" />
          </div>
        </ListItem>
      );
    } else {
      if (isHidden) {
        return (
          <li key={href} data-test="navmenu-menuItemHidden">
            <LocaleLink notStyled as={href} href={hrefAs ? hrefAs : "/[...generic]"}>
              <p>{displayName}</p>
            </LocaleLink>
          </li>
        );
      }
      return (
        <LocaleLink
          notStyled
          akey={displayName}
          href={hrefAs ? hrefAs : "/[...generic]"}
          as={href}
          aClass={classes.linkButton}
        >
          <ListItem
            button
            className={listItemClass}
            onClick={() => {
              setActivePath("");
              setPrevPath("");
              onSelect(href);
            }}
            data-test="navmenu-menuItemVisible"
          >
            {icon && <div className={classes.menuItemIcon}>{icon}</div>}
            <ListItemText primary={displayName} />
            <div className={classes.expandButton}>
              <OpenIcon fontSize="small" />
            </div>
          </ListItem>
        </LocaleLink>
      );
    }
  };

  const back = () => {
    const parts = activePath.split(" > ");
    parts.pop();
    const parent = parts.join(" > ");
    setPrevPath(activePath);
    setActivePath(parent);
  };

  const buildProductCategoryItem = (name: string, tree: any, parentHref: string, parentPath?: string, icon?: any) => {
    let path;
    if (!parentPath) {
      path = name;
    } else {
      path = `${parentPath} > ${name}`;
    }
    const href = `/${name.split(":")[1]}`;
    const subTree = tree[name];
    const children = Object.keys(subTree);
    const showExpandIcons = children.length > 0;
    return buildMenuItem(name, href, showExpandIcons, path, icon);
  };

  const buildCategorySlides = (name: string, categoryTree: any, parentHref: string, parentPath?: string) => {
    let path;
    if (!parentPath) {
      path = name;
    } else {
      path = `${parentPath} > ${name}`;
    }
    const href = `/${name.split(":")[1]}`;
    const children = Object.keys(categoryTree);
    const isExpanded = activePath.startsWith(path);
    const isVisible = activePath === path;
    const needsRender = isExpanded || path == prevPath;

    return [
      needsRender && (
        <NavMenuCategorySlide key={name} href={href} back={back} isExpanded={isExpanded} isVisible={isVisible}>
          <List className={classes.linkList}>
            {buildMenuItem(
              `${t("navmenuAllItems")} ${t(`categories:${name.split(":")[0]}`)}`,
              href,
              undefined,
              undefined,
              undefined,
              classes.showAllItem,
              undefined,
              true
            )}
            {name == "Fahrräder:fahrraeder" &&
              buildMenuItem(
                "Welches Fahrrad passt zu mir?",
                "/welches-fahrrad-passt-zu-mir",
                undefined,
                undefined,
                undefined,
                classes.saleItem,
                "/welches-fahrrad-passt-zu-mir"
              )}
            {name == "E-Bikes:e-bikes" &&
              buildMenuItem(
                "Welches E-Bike passt zu mir?",
                "/welches-ebike-passt-zu-mir",
                undefined,
                undefined,
                undefined,
                classes.saleItem,
                "/welches-ebike-passt-zu-mir"
              )}
            {children.map((key) => {
              return (
                <React.Fragment key={key}>{buildProductCategoryItem(key, categoryTree, href, path)}</React.Fragment>
              );
            })}
          </List>
        </NavMenuCategorySlide>
      ),
      children.map((childName) => {
        if (!needsRender) return null;
        const childTree = categoryTree[childName];
        return buildCategorySlides(childName, childTree, href, path);
      }),
    ];
  };

  const buildRecommendationSlides = () => {
    return [
      <NavMenuCategorySlide
        key="Online Kaufberatung"
        href="/welches-fahrrad-passt-zu-mir"
        back={back}
        isExpanded={activePath.startsWith("Online Kaufberatung")}
        isVisible={activePath === "Online Kaufberatung"}
      >
        <List className={classes.linkList}>
          {buildMenuItem(
            `Alles aus Kaufberatung`,
            "/online-kaufberatung",
            undefined,
            undefined,
            undefined,
            classes.showAllItem,
            "/online-kaufberatung",
            true
          )}
          {buildMenuItem(
            "Welcher Fahrradtyp passt zu mir?",
            "/welches-fahrrad-passt-zu-mir",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-Bio-Clara.svg" />,
            undefined,
            "/welches-fahrrad-passt-zu-mir"
          )}
          {buildMenuItem(
            "Welcher E-Bike Typ passt zu mir?",
            "/welches-ebike-passt-zu-mir",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-E-Clara.svg" />,
            undefined,
            "/welches-ebike-passt-zu-mir"
          )}
          {buildMenuItem(
            "Welches Kinderfahrrad für mein Kind?",
            "/kinderfahrrad-finder",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-Bio-Clara.svg" />,
            undefined,
            "/kinderfahrrad-finder"
          )}
          {buildMenuItem(
            "Welches woom Bike für mein Kind?",
            "/woom-bike-groesse",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-Bio-Clara.svg" />,
            undefined,
            "/woom-bike-groesse"
          )}
          {buildMenuItem(
            "Fahrrad-Vergleich",
            "/fahrrad-vergleich",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-Vergleich.svg" />,
            classes.bikecompareText,
            "/fahrrad-vergleich"
          )}
          {buildMenuItem(
            "E-Bike Vergleich",
            "/ebike-vergleich",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-Vergleich.svg" />,
            classes.bikecompareText,
            "/ebike-vergleich"
          )}
          {buildMenuItem(
            "Produkt-Kaufberatung",
            "/blog/kaufberatung",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-Produkt-Kaufberatung.svg" />,
            undefined,
            "/blog/[generic]"
          )}
          {buildMenuItem(
            "Testberichte",
            "/blog/tests",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-Testberichte.svg" />,
            undefined,
            "/blog/[generic]"
          )}
          {buildMenuItem(
            "Versicherungsrechner",
            "/rechner/fahrrad-e-bike-versicherung-vergleichsrechner",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/Versicherungsrechner.svg" />,
            undefined,
            "/rechner/fahrrad-e-bike-versicherung-vergleichsrechner"
          )}
          {buildMenuItem(
            "Fahrrad vs Auto Rechner",
            "/rechner/fahrrad-vs-auto-rechner",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/Fahrrad-vs-Auto-Rechner.svg" />,
            undefined,
            "/rechner/fahrrad-vs-auto-rechner"
          )}
          {buildMenuItem(
            "Pace-Rechner",
            "/rechner/fahrrad-pace-rechner",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/Navi-Pace-Rechner-Swim-Bike-Run.svg" />,
            undefined,
            "/rechner/fahrrad-pace-rechner"
          )}
          {buildMenuItem(
            "VO2max-Rechner",
            "/rechner/vo2max-rechner",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/vo2maxrechner.svg" />,
            undefined,
            "/rechner/vo2max-rechner"
          )}
          {buildMenuItem(
            "E-Bike-Reichweitenrechner",
            "/rechner/e-bike-reichweitenrechner",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/e-bike-reichweitenrechner.svg" />,
            undefined,
            "/rechner/e-bike-reichweitenrechner"
          )}
          {buildMenuItem(
            "Fahrrad Ritzelrechner",
            "/rechner/fahrrad-ritzelrechner",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/ritzelrechner.svg" />,
            undefined,
            "/rechner/fahrrad-ritzelrechner"
          )}
          {buildMenuItem(
            "Lebenserwartungsrechner",
            "/rechner/lebenserwartungsrechner",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/lebenserwartungsrechner.svg" />,
            undefined,
            "/rechner/lebenserwartungsrechner"
          )}
          {buildMenuItem(
            "Geschwindigkeitsrechner",
            "/rechner/geschwindigkeitsrechner",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/geschwindigkeitsrechner.svg" />,
            undefined,
            "/rechner/geschwindigkeitsrechner"
          )}
          {buildMenuItem(
            "Pulsrechner",
            "/rechner/trainingspuls-maximalpuls-rechner",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/trainingspuls-maximalpuls-rechner.svg" />,
            undefined,
            "/rechner/trainingspuls-maximalpuls-rechner"
          )}
          {buildMenuItem(
            "Fahrrad Größenrechner",
            "/kaufberatung-fahrradgroesse",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-Fahrradgroesse.svg" />,
            undefined,
            "/kaufberatung-fahrradgroesse"
          )}
          {buildMenuItem(
            "Kettenlängen-Rechner",
            "/rechner/kettenlaenge-berechnen",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-Kettenlaenge.svg" />,
            undefined,
            "/rechner/kettenlaenge-berechnen"
          )}
          {buildMenuItem(
            "Kalorienverbrauch-Rechner",
            "/rechner/kalorienverbrauch-rechner",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/Kalorienrechner.svg" />,
            undefined,
            "/rechner/kalorienverbrauch-rechner"
          )}
          {buildMenuItem(
            "Lux-Lumen-Rechner",
            "/rechner/lumen-lux-rechner",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/Lux-Lumen-Rechner.svg" />,
            undefined,
            "/rechner/lumen-lux-rechner"
          )}
          {buildMenuItem(
            "Radumfang-Rechner",
            "/rechner/reifenumfang-rechner",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/Radumfang-Rechner.svg" />,
            undefined,
            "/rechner/reifenumfang-rechner"
          )}
          {buildMenuItem(
            "Reifendruck-Rechner",
            "/rechner/fahrrad-reifendruck-rechner",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/Reifendruck-Rechner.svg" />,
            undefined,
            "/rechner/fahrrad-reifendruck-rechner"
          )}
          {buildMenuItem(
            "Fahrrad-Restwert-Rechner",
            "/rechner/fahrrad-restwert-rechner",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/Fahrrad-Restwertrechner.svg" />,
            undefined,
            "/rechner/fahrrad-restwert-rechner"
          )}
          {buildMenuItem(
            "Speichenlängen-Rechner",
            "/rechner/fahrrad-speichenrechner",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/Speichenlaengen-Rechner.svg" />,
            undefined,
            "/rechner/fahrrad-speichenrechner"
          )}
          {buildMenuItem(
            "Watt-Rechner",
            "/rechner/fahrrad-wattrechner",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/Fahrrad-Wattrechner.svg" />,
            undefined,
            "/rechner/fahrrad-wattrechner"
          )}
          {buildMenuItem(
            "Bar-PSI-Rechner",
            "/rechner/psi-bar-rechner",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/bar-psi.svg" />,
            undefined,
            "/rechner/psi-bar-rechner"
          )}
          {buildMenuItem(
            "Ausreißer-Rechner",
            "/rechner/ausreisser-rechner",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/ausreisser.svg" />,
            undefined,
            "/rechner/ausreisser-rechner"
          )}
          {buildMenuItem(
            "Das beste Fahrrad für dein Budget",
            "/beste/fahrraeder",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-Bestes-Bio-Bike.svg" />,
            undefined,
            "/beste/[generic]"
          )}
          {buildMenuItem(
            "Das beste E-Bike für dein Budget",
            "/beste/e-bikes",
            undefined,
            undefined,
            <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-Bestes-E-Bike.svg" />,
            undefined,
            "/beste/[generic]"
          )}
        </List>
      </NavMenuCategorySlide>,
    ];
  };

  return (
    <div className={classes.navmenu}>
      <List className={`${classes.linkList} ${classes.topLevelList}`} data-test="navmenu-topLevelList">
        {buildProductCategoryItem(
          "Fahrräder:fahrraeder",
          categoryTree,
          undefined,
          undefined,
          <CategoryIcon
            className={classes.bikeIcon}
            img="https://images.thecycleverse.com/navmenu/Icon-Fahrraeder.svg"
          />
        )}
        {buildProductCategoryItem(
          "E-Bikes:e-bikes",
          categoryTree,
          undefined,
          undefined,
          <CategoryIcon className={classes.bikeIcon} img="https://images.thecycleverse.com/navmenu/Icon-E-Bikes.svg" />
        )}
        {buildMenuItem(
          "Online Kaufberatung",
          "/welches-fahrrad-passt-zu-mir",
          true,
          "Online Kaufberatung",
          <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-Kaufberatung-Gruen.svg" />,
          classes.adviceItem,
          "/welches-fahrrad-passt-zu-mir"
        )}
        {buildProductCategoryItem(
          "Ausrüstung:ausruestung",
          categoryTree,
          undefined,
          undefined,
          <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-Ausruestung.svg" />
        )}
        {buildProductCategoryItem(
          "Komponenten:komponenten",
          categoryTree,
          undefined,
          undefined,
          <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-Komponenten.svg" />
        )}
        {buildProductCategoryItem(
          "Bekleidung:bekleidung",
          categoryTree,
          undefined,
          undefined,
          <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-Bekleidung.svg" />
        )}
        {buildMenuItem(
          "Cycling Hacks",
          "/blog",
          undefined,
          undefined,
          <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-Cycling-Hacks.svg" />,
          classes.cyclinghacksItem,
          "/blog"
        )}
        {buildProductCategoryItem(
          "Wearables:wearables",
          categoryTree,
          undefined,
          undefined,
          <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-Wearables.svg" />
        )}
        {buildMenuItem(
          "Apps & Digitale Services:apps-digitale-services",
          "/apps-digitale-services/allapps",
          undefined,
          undefined,
          <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-Apps_Dienste.svg" />,
          undefined,
          "/apps-digitale-services/[generic]"
        )}
        {buildProductCategoryItem(
          "Medien & Literatur:medien-literatur",
          categoryTree,
          undefined,
          undefined,
          <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-Medien_Literatur.svg" />
        )}
        {buildProductCategoryItem(
          "Ernährung, Körperpflege & Hygiene:ernaehrung-koerperpflege-hygiene",
          categoryTree,
          undefined,
          undefined,
          <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-Ernaehrung.svg" />
        )}
        {buildMenuItem(
          "Endspurt %",
          "/products/sale",
          undefined,
          undefined,
          <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-Endspurt.svg" />,
          classes.saleItem,
          "/products/sale"
        )}
        {buildProductCategoryItem(
          "Training zu Hause:training-zu-hause",
          categoryTree,
          undefined,
          undefined,
          <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-Training-zu-Hause.svg" />
        )}
        {buildProductCategoryItem(
          "Sportarten:sportarten",
          categoryTree,
          undefined,
          undefined,
          <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-Sportarten.svg" />
        )}
        {buildProductCategoryItem(
          "Gutscheine & Geschenke:gutscheine-geschenke",
          categoryTree,
          undefined,
          undefined,
          <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-Gutscheine.svg" />
        )}
        {buildMenuItem(
          "Marken",
          "/marken",
          undefined,
          undefined,
          <CategoryIcon img="https://images.thecycleverse.com/navmenu/Marken.svg" />,
          classes.brandItem,
          "/marken"
        )}
        {buildMenuItem(
          "Radreisen",
          "/journeys",
          undefined,
          undefined,
          <CategoryIcon img="https://images.thecycleverse.com/navmenu/Icon-Radreisen.svg" />,
          classes.journeysItem,
          "/journeys"
        )}
      </List>

      {Object.keys(categoryTree).map((key) => {
        const subTree = categoryTree[key];
        const href = key.split(":")[1];
        return buildCategorySlides(key, subTree, href);
      })}

      {buildRecommendationSlides()}
    </div>
  );
};

export default NavMenu;
