import ScrollUpButton from "react-scroll-up-button";

const ScrollTopButton = (props) => {
  const styles = {
    MainStyle: {
      // backgroundColor: 'rgb(255, 255, 255)',
      // borderRadius: '50%',
      border: "none",
      height: 35,
      width: 35,
      position: "fixed",
      bottom: 20,
      WebkitTransition: "all 0.5s ease-in-out",
      transition: "all 0.5s ease-in-out",
      transitionProperty: "opacity, right",
      cursor: "pointer",
      opacity: 0,
      right: -75,
      zIndex: 2,
      outline: 0,
    },
    ToggledStyle: {
      opacity: 1,
      right: 20,
    },
    icon: {
      height: 35,
      width: 35,
      backgroundImage: "url('/images/Scroll-Up-clean.svg')",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
  };
  const { style, ToggledStyle } = props;
  return (
    <ScrollUpButton
      {...props}
      style={{
        ...styles.MainStyle,
        ...style,
      }}
      ToggledStyle={{
        ...styles.ToggledStyle,
        ...ToggledStyle,
      }}
    >
      <div style={{ ...styles.icon }}></div>
    </ScrollUpButton>
  );
};

export default ScrollTopButton;
