import { Grid, Typography, makeStyles, useMediaQuery } from "@material-ui/core";

import Analytics from "src/lib/analytics/analytics";
import { SearchBarColumnContainer } from "./SearchBarCardComponents";
import { TheCycleverseTheme } from "src/theme";
import environment from "src/environment";
import { getProductInfoLink } from "../Product/useProductLink";
import { useCallback } from "react";
import useTranslation from "next-translate/useTranslation";
import useTranslationExtras from "src/translations/useTranslationExtras";

const getImageUrl = (product, isDesktopView) => {
  let result;
  if (isDesktopView) {
    if (product.renditionLarge) {
      result = product.renditionLarge;
    } else if (product.images) {
      result = product.images[0].uri;
    }
  } else {
    if (product.renditionMedium) {
      result = product.renditionMedium;
    } else if (product.imagesSmall && product.imagesSmall[0]) {
      result = product.imagesSmall[0].uri;
    } else if (product.images) {
      result = product.images[0].uri;
    }
  }
  return result;
};

const useStylesRecommendations = makeStyles((theme: TheCycleverseTheme) => ({
  item: {
    margin: "0px",
    fontSize: "initial",
    cursor: "pointer",
    marginBottom: "3px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    minHeight: "60px",
  },
  productTitle: {
    color: theme.hightlightColor,
  },
  productsCardContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
  },
  productCard: {
    alignItems: "center",
    width: "100%",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      alignItems: "center",
      width: "100%",
    },
  },
  image: {
    width: 85,
    float: "right",
    marginLeft: 5,
    [theme.breakpoints.up("sm")]: {
      float: "left",
      marginRight: 10,
      marginLeft: 0,
    },
  },
  productBig: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    "&:hover": {
      backgroundColor: theme.unflashyerColor,
    },
  },
  productTitleText: {
    display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    overflowWrap: "break-word",
    [theme.breakpoints.up("md")]: {
      fontSize: "0.90rem",
      whiteSpace: "normal",
    },
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  productContainerLink: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  itemImage: {
    width: "60px",
    maxHeight: "60px",
    marginRight: "10px",
    "object-fit": "contain",
    [theme.breakpoints.down(400)]: {
      width: "50px",
      maxHeight: "50px",
      marginRight: "10px",
    },
  },
}));

type SearchBarRecommendationsProductType = {
  products: Array<any>;
  searchValue: string;
  onSearchMore?: Function;
};

const SearchBarRecommendationsProduct = (props: SearchBarRecommendationsProductType) => {
  const { products, searchValue, onSearchMore = () => {} } = props;
  const { t, lang: locale } = useTranslation("common");
  const classes = useStylesRecommendations();
  const isDesktopView = useMediaQuery((theme: TheCycleverseTheme) => theme.breakpoints.up("sm"));
  const { priceAsFloat } = useTranslationExtras(t, locale);

  const handleProductClick = useCallback(
    (product) => {
      if (product) {
        Analytics.productClick(product.id, product.seoId, product.title, {
          advertiser: product.advertiser,
          category: product.category,
          priceAmount: priceAsFloat(product.price),
          priceCurrency: product.price.currency,
        });
      }
    },
    [priceAsFloat]
  );

  return (
    <SearchBarColumnContainer
      findMoreText={t("showAllResult")}
      findMorePath={`/products/search?s=${searchValue}`}
      title={t("searchedProductsTitle")}
      titleClassName={classes.productTitle}
      onSearchMore={onSearchMore}
    >
      {products.map((value, index) => (
        <a
          key={index}
          className={`${classes.link} ${classes.productContainerLink}`}
          href={getProductInfoLink(value, locale)}
          hrefLang={value.locale}
          onClick={() => {
            handleProductClick(value);
          }}
        >
          <li
            data-test="SearchBarRecommendationProduct"
            className={`FX-recommendations-item ${classes.item} ${classes.productCard}`}
            key={index}
          >
            <div className={classes.productBig}>
              <Grid item>
                <img src={getImageUrl(value, isDesktopView)} className={classes.itemImage} alt="product image" />
              </Grid>
              <Grid item>
                <Typography>{value.brand}</Typography>
                <Typography className={classes.productTitleText}>{value.title}</Typography>
              </Grid>
            </div>
          </li>
        </a>
      ))}
    </SearchBarColumnContainer>
  );
};

export default SearchBarRecommendationsProduct;
