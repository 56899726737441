import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BackIcon from "./Icons/BackIcon";
import Slide from "@material-ui/core/Slide";
import nanoid from "nanoid";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme) => ({
  slide: {
    position: "absolute",
    top: 0,
    width: "100%",
    minHeight: "100%",
    display: "grid",
  },
  slideContent: {
    backgroundColor: "white",
    width: "100%",
    minHeight: 900,
  },
  slideHeader: {
    backgroundColor: theme.unflashyerColor,
    height: 48,
    cursor: "pointer",
  },
  backIcon: {
    display: "table-cell",
    verticalAlign: "middle",
    width: 40,
    height: 40,
    padding: 18,
    "& .MuiIcon-root": {
      height: 12,
      width: 12,
    },
  },
  backText: {
    display: "table-cell",
    verticalAlign: "middle",
    fontSize: "medium",
    fontWeight: 400,
  },
  hidden: {
    // visibility: 'hidden',
    maxHeight: 0,
    overflow: "hidden",
  },
}));

const NavMenuCategorySlide = (props) => {
  const { href, back, isExpanded, isVisible } = props;
  const classes = useStyles();
  const id = `FX-NavMenu-Slide-${nanoid(5)}`;
  const { t } = useTranslation("common");
  const timeout = {
    appear: 0,
    enter: 300,
    exit: 500,
  };

  const scrollTop = () => {
    const el = document.getElementById(id);
    el.scrollIntoView();
  };

  return (
    <Slide
      key={href}
      direction="right"
      timeout={timeout}
      onEntering={() => {
        scrollTop();
      }}
      in={isExpanded}
      className={`${classes.slide} ${isVisible ? "" : classes.hidden}`}
      mountOnEnter
      unmountOnExit
    >
      <div>
        <div className={classes.slideContent}>
          <div
            id={id}
            className={classes.slideHeader}
            onClick={() => {
              back();
            }}
          >
            <div className={classes.backIcon}>
              <BackIcon />
            </div>
            <div className={classes.backText}>{t("navmenuBack")}</div>
          </div>
          <div className={classes.slideBody}>{props.children}</div>
        </div>
      </div>
    </Slide>
  );
};

export default NavMenuCategorySlide;
