import React, { FC, ReactElement } from "react";

import Button from "@material-ui/core/Button";
import ButtonLink from "../ButtonLink";
import { TheCycleverseTheme } from "src/theme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  button: {
    color: theme.palette.primary.main,
    textTransform: "inherit",
    fontWeight: theme.typography.fontWeight,
    fontSize: "medium",
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: 0,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.footerLinkOffet,
    },
    "& span": {
      textAlign: "left",
    },
  },
  link: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

export type FooterLinkProps = {
  href?: string;
  name: string;
  className?: string;
  onClick?: () => void;
  noLink?: boolean;
};

const FooterLink: FC<FooterLinkProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("footer");

  return props.noLink ? (
    <Button
      component="div"
      className={`${classes.button} ${props.className ? props.className : ""}`}
      onClick={props.onClick ? props.onClick : null}
    >
      {t(props.name)}
    </Button>
  ) : (
    <ButtonLink
      aClassName={classes.link}
      buttonClassName={`${classes.button} ${props.className ? props.className : ""}`}
      href={props.href}
      as={props.href}
      prefetch={false}
      onClick={props.onClick ? props.onClick : null}
    >
      {t(props.name)}
    </ButtonLink>
  );
};

export default FooterLink;
