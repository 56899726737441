import { useContext, useEffect, useState } from "react";

import AdContext from "../Ads/AdContext";
import Analytics from "src/lib/analytics/analytics";
import Head from "next/head";
import Script from "next/script";
import environment from "src/environment";
import { useCallback } from "react";
import { useEffectOnce } from "react-use";
import { useEvent } from "react-use";

const CookieConsentCMP = () => {
  const { adsEnabled, setAdsEnabled } = useContext(AdContext);
  const [statisticsCookiesAllowed, setStatisticsCookiesAllowed] = useState(false);
  const [marketingCookiesAllowed, setMarketingCookiesAllowed] = useState(false);
  const [userId, setUserId] = useState();

  useEffectOnce(() => {
    Analytics.getUserId().then((userId) => {
      setUserId(userId);
    });
  });

  const onAccept = useCallback(
    (e) => {
      if (typeof window !== "undefined" && window.Cookiebot) {
        setAdsEnabled(window.Cookiebot.consent?.marketing);
        setMarketingCookiesAllowed(window.Cookiebot.consent?.marketing);
        setStatisticsCookiesAllowed(window.Cookiebot.consent?.statistics);
        Analytics.cookieAccept();
      }
    },
    [setAdsEnabled, setMarketingCookiesAllowed, setStatisticsCookiesAllowed]
  );
  useEvent("CookiebotOnAccept", onAccept, typeof window === "undefined" ? null : window, { capture: true });

  const onDecline = useCallback(
    (e) => {
      if (typeof window !== "undefined" && window.Cookiebot) {
        setAdsEnabled(window.Cookiebot.consent?.marketing);
        setMarketingCookiesAllowed(window.Cookiebot.consent?.marketing);
        setStatisticsCookiesAllowed(window.Cookiebot.consent?.statistics);
        Analytics.cookieDecline();
      }
    },
    [setAdsEnabled, setMarketingCookiesAllowed, setStatisticsCookiesAllowed]
  );
  useEvent("CookiebotOnDecline", onDecline, typeof window === "undefined" ? null : window, { capture: true });

  useEffect(() => {
    if (typeof window !== "undefined" && window.Cookiebot) {
      setAdsEnabled(window.Cookiebot.consent?.marketing);
      setMarketingCookiesAllowed(window.Cookiebot.consent?.marketing);
      setStatisticsCookiesAllowed(window.Cookiebot.consent?.statistics);
      window["gtag_enable_tcf_support"] = true;
    }
  }, [setAdsEnabled, setMarketingCookiesAllowed, setStatisticsCookiesAllowed]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];
      const gtag = (...args: any[]) => {
        window.dataLayer.push(args);
      };
      gtag("consent", "default", {
        ad_storage: "denied",
        analytics_storage: "denied",
        functionality_storage: "denied",
        personalization_storage: "denied",
        security_storage: "granted",
        wait_for_update: 500,
      });
      gtag("set", "ads_data_redaction", true);
      gtag("set", "url_passthrough", true);
    }
  });

  return (
    <>
      <Head>
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="106442a3-394b-4100-88ee-a99f0c695e5b"
          data-framework="IAB"
          data-widget-enabled="false"
          type="text/javascript"
          async
        ></script>
        <style type="text/css">
          {`

            #CybotCookiebotDialog .CybotCookiebotDialogContentWrapper #CybotCookiebotDialogBodyButtonDecline, #CybotCookiebotDialog .CybotCookiebotDialogContentWrapper #CybotCookiebotDialogBodyLevelButtonCustomize {
              color: gray;
            }

            #CybotCookiebotDialog .CybotCookiebotDialogContentWrapper #CybotCookiebotDialogBodyLevelButtonCustomize .CybotCookiebotDialogArrow {
              display: none;
            }


            #CybotCookiebotDialog .CybotCookiebotDialogContentWrapper *, #CybotCookiebotDialogBodyUnderlay .CybotCookiebotDialogContentWrapper * {
              font-family: 'Roboto';
            }

            #CybotCookiebotDialog .CybotCookiebotDialogContentWrapper .CybotCookiebotDialogNavItem .CybotCookiebotDialogNavItemLink {
              font-weight: 900;
            }

            #CybotCookiebotDialog .CybotCookiebotDialogContentWrapper #CybotCookiebotDialogBodyContentTitle {
              font-family: "DM Serif Text Regular";
            }

            #CybotCookiebotDialog .CybotCookiebotDialogContentWrapper #CybotCookiebotDialogBodyContentTitle {
              font-size: 20px;
            }

            a#CybotCookiebotDialogPoweredbyCybot,
            div#CybotCookiebotDialogPoweredByText {
              display: none;
            }

            #CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
              display: none; 
            }

            .CybotCookiebotDialogContentWrapper #CybotCookiebotDialogHeader {
              padding: 5px 0px 10px 0px;
            }

            .CybotCookiebotDialogContentWrapper #CybotCookiebotDialogHeaderLogosWrapper {
              justify-content: center;
            }

            .CybotCookiebotDialogContentWrapper #CybotCookiebotDialogPoweredbyLink {
              width: inherit;
            }

            .CybotCookiebotDialogContentWrapper #CybotCookiebotDialogPoweredbyImage {
              max-height: 3em;
            }

            .CybotCookiebotDialogContentWrapper #CybotCookiebotDialogBodyContent #CybotCookiebotDialogBodyContentTextShowIABVendors,
            .CybotCookiebotDialogContentWrapper #CybotCookiebotDialogBodyContent #CybotCookiebotDialogBodyContentTextToggleDetails {
              color: inherit;
              font-weight: inherit;
            }
          `}
        </style>
      </Head>

      {statisticsCookiesAllowed && userId && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsId4}`}
            strategy="afterInteractive"
          />

          <Script id="google-analytics" strategy="afterInteractive">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){window.dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${environment.googleAnalyticsId4}', {
                'user_id': '${userId}',
                'send_page_view': false
              });
              `}
          </Script>
        </>
      )}

      {environment.name === "prod" && marketingCookiesAllowed && userId && (
        <>
          <Script strategy="afterInteractive">
            {`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '${environment.facebookPixelId}', {'external_id': '${userId}'});
                fbq('track', 'PageView');
              `}
          </Script>
          <noscript>
            <img
              height="1"
              width="1"
              alt=""
              style={{ display: "none" }}
              src={`https://www.facebook.com/tr?id=${environment.facebookPixelId}&ev=PageView&noscript=1`}
            />
          </noscript>
        </>
      )}

      {environment.name === "prod" && statisticsCookiesAllowed && (
        <>
          <Script
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                  (function(h,o,t,j,a,r){
                    setTimeout(function(){
                      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                      h._hjSettings={hjid:2257837,hjsv:6};
                      a=o.getElementsByTagName('head')[0];
                      r=o.createElement('script');r.async=1;
                      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                      a.appendChild(r);
                    }, 5000)
                  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                `,
            }}
          />
        </>
      )}
    </>
  );
};

export default CookieConsentCMP;
