import Button from "@material-ui/core/Button";
import ButtonLink from "../ButtonLink";
import FooterLink from "./FooterLink";
import FooterSegment from "./FooterSegment";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React from "react";
import SubscribeForm from "../../Newsletter/SubscribeForm";
import { TheCycleverseTheme } from "src/theme";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import environment from "../../../environment";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  root: {
    padding: theme.spacing(2),
    textAlign: "center",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
  footer: {
    backgroundColor: theme.palette.secondary.main,
  },
  title: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundImage: "url('/images/Logo.svg')",
    height: 50,
    width: 260,
  },
  copyright: {
    marginTop: theme.spacing(4),
    fontSize: "small",
  },
  button: {
    color: theme.palette.primary.main,
    textTransform: "inherit",
    fontWeight: theme.typography.fontWeight,
    fontSize: "medium",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  socialMediaIcon: {
    display: "inline-block",
    width: 40,
    height: 40,
    marginLeft: 10,
    "& div": {
      height: "100%",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
    },
  },
  socialMediaSection: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  feedbackButton: {
    marginRight: 10,
    marginTop: 4,
    display: "inline-block",
    backgroundColor: "#04B0E9",
    color: "#fff",
    top: "50%",
    "-ms-transform": "translateY(-50%)",
    transform: "translateY(-50%)",
    "&:hover": {
      backgroundColor: "#04B0E9",
    },
  },
  priceDisclamer: {
    textAlign: "left",
    color: theme.palette.primary.main,
    backgroundColor: theme.disclaimerColor,
    fontSize: "xx-small",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.globalMarginDesktop,
    paddingRight: theme.globalMarginDesktop,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.globalMarginMobile,
      paddingRight: theme.globalMarginMobile,
    },
  },
  spacingBottom: {
    paddingBottom: 40,
  },
  header: {
    fontSize: 20,
    fontWeight: 300,
    marginBottom: 40,
  },
  highlightColor1: {
    color: theme.positiveAlternateColor,
  },
  highlightColor2: {
    color: theme.hightlightColorTertiary,
  },
  categorySegment: {
    color: theme.positiveColor,
    fontWeight: 300,
  },
  viewAllLink: {
    color: theme.unflashyColor,
    fontSize: 14,
  },
  newsletter: {
    backgroundColor: theme.newsletterFooter,
  },
  newsletterSubscribe: {
    margin: "auto",
    backgroundColor: theme.newsletterFooter,
    maxWidth: theme.widths.contentWidth,
    "& .FX-SubscribeForm-Form": {
      maxWidth: 300,
      margin: "auto",
    },
  },
  newsletterHighlightTeaser: {
    textAlign: "center",
    width: "100%",
    fontWeight: 900,
    fontSize: 18,
    lineHeight: "19px",
  },
  newsletterHighlightHeadline: {
    textAlign: "center",
    width: "100%",
    color: theme.newsletterOrangeTitle,
    fontFamily: theme.highlightFontFamily,
    fontWeight: 600,
    fontSize: 30,
    lineHeight: "31px",
  },
  newsletterDescription: {
    maxWidth: 435,
    margin: "auto",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}));

const addLink = (href, name, className = undefined) => {
  return (
    <Grid item key={name}>
      <FooterLink href={href} name={name} className={className} />
    </Grid>
  );
};

const Footer = (props) => {
  const { variant } = props;
  const classes = useStyles();
  const { t } = useTranslation("footer");
  const isSizeXS = useMediaQuery((theme: TheCycleverseTheme) => theme.breakpoints.down("xs"));
  const isSizeSM = useMediaQuery((theme: TheCycleverseTheme) => theme.breakpoints.down("sm"));

  const addLinkSingles = (items: { href: string; name: string }[]) => {
    const calcOrder = (idx) => {
      if (isSizeXS) return idx;
      const columnCount = isSizeSM ? 2 : 3;
      const rowCount = Math.ceil(items.length / columnCount);
      return (idx % rowCount) * columnCount + Math.floor(idx / rowCount);

      /*
      0   1   2
      3   4   5
      6   7   8
      9  10  11
      12 13
      
      0   5  10
      1   6  11
      2   7  12
      3   8  13
      4   9

      0 -> 0    0 + 0
      1 -> 3    1*col + 0
      2 -> 6    2*col + 0
      3 -> 9    3*col + 0
      4 -> 12   4*col + 0
      5 -> 1    0*col + 1
      6 -> 4    1*col + 1
      7 -> 7    2*col + 1
      8 -> 10   3*col + 1
      9 -> 13   4*col + 1
      10 -> 2   0*col + 2
      11 -> 5   1*col + 2
      12 -> 8   2*col + 2 
      13 -> 11  3*col + 2
      */
    };
    return items.map((x, i) => (
      <Grid key={i} item container justifyContent="flex-start" xs={12} sm={6} md={4} style={{ order: calcOrder(i) }}>
        <FooterLink href={x.href} name={x.name} />
      </Grid>
    ));
  };

  return (
    <footer className={classes.footer} data-test="footer-main">
      <div className={classes.newsletter}>
        <SubscribeForm
          afterSubscribe={() => {}}
          close={() => {}}
          mailchimpUrl={environment.mailchimpNewsletterUrl}
          page="footer"
          formVariant="compact"
          className={classes.newsletterSubscribe}
          showPrivacyNote={false}
          ctaElement={
            <>
              <div className={classes.newsletterHighlightTeaser}>{t("newsletterHighlightTeaser")}</div>
              <div className={classes.newsletterHighlightHeadline}>{t("newsletterHighlightHeadline")}</div>
              <div className={classes.newsletterDescription}>{t("newsletterDescription")}</div>
            </>
          }
        />
      </div>
      <Paper className={classes.root} elevation={0}>
        <Grid container>
          <Grid item xs={12}>
            <ButtonLink href="/" as="/" prefetch={false}>
              <div className={classes.title} />
            </ButtonLink>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.socialMediaSection}>
              {variant !== "partner" && (
                <Button
                  className={classes.feedbackButton}
                  onClick={() => {
                    window.location.href = "mailto:feedback@thecycleverse.com?subject=The Cycleverse Feedback";
                  }}
                >
                  Feedback
                </Button>
              )}
              <a
                href="https://www.facebook.com/thecycleverse/"
                target="_blank"
                rel="noopener nofollow"
                className={classes.socialMediaIcon}
              >
                <div style={{ backgroundImage: "url('/images/logo_fb.svg')" }} />
              </a>
              <a
                href="https://www.instagram.com/thecycleverse/?hl=de"
                target="_blank"
                rel="noopener nofollow"
                className={classes.socialMediaIcon}
              >
                <div style={{ backgroundImage: "url('/images/logo_ig.svg')" }} />
              </a>
              <a
                href="https://www.pinterest.de/TheCycleverse/_saved/"
                target="_blank"
                rel="noopener nofollow"
                className={classes.socialMediaIcon}
              >
                <div style={{ backgroundImage: "url('/images/logo_pn.svg')" }} />
              </a>
            </div>
          </Grid>
          <Grid item container justifyContent="center" alignItems="flex-start">
            <Grid item xs={12}>
              <h3 className={clsx(classes.header, classes.highlightColor1)}>{t("buyingAdvice")}</h3>
            </Grid>
            <Grid item xs={12} sm={8} md={6} className={classes.spacingBottom}>
              <Grid container justifyContent="flex-start" alignItems="flex-start">
                {addLinkSingles([
                  { href: "/welches-fahrrad-passt-zu-mir", name: "biobikeFinder" },
                  { href: "/welches-ebike-passt-zu-mir", name: "ebikeFinder" },
                  { href: "/kinderfahrrad-finder", name: "kinderfahrradFinder" },
                  { href: "/woom-bike-groesse", name: "woombikeFinder" },
                  { href: "/fahrrad-vergleich", name: "bioBikeCompare" },
                  { href: "/ebike-vergleich", name: "ebikeCompare" },
                  { href: "/kaufberatung-fahrradgroesse", name: "bikesizeCalc" },
                  { href: "/rechner/kettenlaenge-berechnen", name: "chainlengthCalc" },
                  { href: "/rechner/kalorienverbrauch-rechner", name: "caloriesCalc" },
                  { href: "/rechner/lumen-lux-rechner", name: "lumenluxCalc" },
                  { href: "/rechner/reifenumfang-rechner", name: "wheelsizeCalc" },
                  { href: "/rechner/fahrrad-reifendruck-rechner", name: "pressureCalc" },
                  { href: "/rechner/fahrrad-restwert-rechner", name: "bikevalueCalc" },
                  { href: "/rechner/fahrrad-speichenrechner", name: "spokeCalc" },
                  { href: "/rechner/fahrrad-wattrechner", name: "wattCalc" },
                  { href: "/rechner/psi-bar-rechner", name: "psibarCalc" },
                  { href: "/rechner/ausreisser-rechner", name: "ausreisserCalc" },
                  { href: "/rechner/e-bike-reichweitenrechner", name: "ebikeRangeCalc" },
                  { href: "/rechner/fahrrad-ritzelrechner", name: "ritzelrechnerCalc" },
                  { href: "/rechner/lebenserwartungsrechner", name: "lebenserwartungCalc" },
                  { href: "/rechner/geschwindigkeitsrechner", name: "geschwindigkeitCalc" },
                  { href: "/rechner/trainingspuls-maximalpuls-rechner", name: "pulseCalc" },
                  { href: "/rechner/fahrrad-pace-rechner", name: "paceCalc" },
                  { href: "/rechner/vo2max-rechner", name: "vo2maxCalc" },
                  { href: "/rechner/fahrrad-vs-auto-rechner", name: "bikeVsCarCalc" },
                  { href: "/rechner/fahrrad-e-bike-versicherung-vergleichsrechner", name: "insuranceCalc" },
                ])}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={2} className={classes.spacingBottom}>
              <FooterSegment
                headline={t("thecycleverse")}
                content={[
                  addLink("/about", "aboutUs"),
                  addLink("/partner", "partner", classes.highlightColor2),
                  addLink("/blogger", "blogger"),
                  addLink("/privacy", "privacy"),
                  <Grid item key="cookiePrefs">
                    <FooterLink
                      name={"cookiePrefs"}
                      onClick={() => {
                        if (typeof window !== "undefined" && window.Cookiebot) {
                          window.Cookiebot.show();
                        }
                      }}
                      noLink
                    />
                  </Grid>,
                  addLink("/contact", "imprint"),
                ]}
              />
            </Grid>

            <Grid item xs={12}>
              <h3 className={classes.header}>{t("topTopics")}</h3>
            </Grid>
            <Grid item xs={12} sm={4} md={2} className={classes.spacingBottom}>
              <FooterSegment
                headline={t("bestBiobikes")}
                classNameHeadline={classes.highlightColor1}
                content={[
                  addLink("/beste/gravel-unter-2400", "gravelr2400"),
                  addLink("/beste/mtb-hardtails-unter-1200", "mtbHardTail1200"),
                  addLink("/beste/high-end-mtb-fullys", "highendMtbFullys"),
                  addLink("/beste/road-unter-2400", "RoadBikes2400"),
                  addLink("/beste/fahrraeder", "viewAll", classes.viewAllLink),
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2} className={classes.spacingBottom}>
              <FooterSegment
                headline={t("bestEbikes")}
                classNameHeadline={classes.highlightColor1}
                content={[
                  addLink("/beste/e-trekking-unter-2500", "eTrekking2500"),
                  addLink("/beste/e-urban-unter-3000", "eUrban3000"),
                  addLink("/beste/high-end-e-gravel", "highendEGravel"),
                  addLink("/beste/e-mtb-fully-unter-7500", "eMtbFully7500"),
                  addLink("/beste/e-bikes", "viewAll", classes.viewAllLink),
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2} className={classes.spacingBottom}>
              <FooterSegment
                headline={t("otherLinks")}
                content={[
                  addLink("/products/sale", "dealOfTheDay"),
                  addLink("/training-zu-hause", "trainHome"),
                  addLink("/sportarten", "sports"),
                  addLink("/gutscheine-geschenke", "gifts"),
                  addLink("/ernaehrung-koerperpflege-hygiene", "nutrition"),
                  addLink("/marken", "brands"),
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2} className={classes.spacingBottom}>
              <FooterSegment
                headline={t("cyclingHacks")}
                content={[
                  addLink("/blog/tests", "hackTests"),
                  addLink("/blog/kaufberatung", "hackAdvice"),
                  addLink("/blog/tricks", "hackTipps"),
                  addLink("/blog/knowhow", "hackKnowledge"),
                  addLink("/blog/food", "hackNutrition"),
                  addLink("/blog/interviews", "hackInterview"),
                  addLink("/blog/innovation", "hackInnovation"),
                ]}
              />
            </Grid>

            <Grid item xs={12}>
              <h3 className={classes.header}>{t("topCategories")}</h3>
            </Grid>
            <Grid item xs={12} sm={8} md={6}>
              <Grid container justifyContent="flex-start" alignItems="flex-start">
                <Grid item xs={12} sm={6} md={4} className={classes.spacingBottom}>
                  <FooterSegment
                    headline={t("catBioBikes")}
                    classNameHeadline={classes.categorySegment}
                    content={[
                      addLink("/trekking-radreise", "catTrekking"),
                      addLink("/road", "catRoad"),
                      addLink("/gravel", "catGravel"),
                      addLink("/mtb-fullys", "catMtbFully"),
                      addLink("/mtb-dirt-jump", "catDirt"),
                      addLink("/fahrraeder", "viewAll", classes.viewAllLink),
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={classes.spacingBottom}>
                  <FooterSegment
                    headline={t("catEBikes")}
                    classNameHeadline={classes.categorySegment}
                    content={[
                      addLink("/e-trekking", "catETrekking"),
                      addLink("/e-urban", "catEUrban"),
                      addLink("/e-mtb-fully", "catEMtbFully"),
                      addLink("/e-cross", "catECross"),
                      addLink("/e-gravel", "catEGravel"),
                      addLink("/e-bikes", "viewAll", classes.viewAllLink),
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={classes.spacingBottom}>
                  <FooterSegment
                    headline={t("catCloth")}
                    classNameHeadline={classes.categorySegment}
                    content={[
                      addLink("/kopf", "catHead"),
                      addLink("/brillen-goggles", "catGoggles"),
                      addLink("/oberkoerper", "catTorso"),
                      addLink("/fuesse", "catFeet"),
                      addLink("/protektoren", "catProtect"),
                      addLink("/bekleidung", "viewAll", classes.viewAllLink),
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={classes.spacingBottom}>
                  <FooterSegment
                    headline={t("catEquip")}
                    classNameHeadline={classes.categorySegment}
                    content={[
                      addLink("/tachos-navigation", "catTacho"),
                      addLink("/fahrradanhaenger", "catAnhaenger"),
                      addLink("/rucksaecke-fahrradtaschen", "catBags"),
                      addLink("/beleuchtung", "catLights"),
                      addLink("/bikepacking-outdoor", "catBikepacking"),
                      addLink("/ausruestung", "viewAll", classes.viewAllLink),
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={classes.spacingBottom}>
                  <FooterSegment
                    headline={t("catComponents")}
                    classNameHeadline={classes.categorySegment}
                    content={[
                      addLink("/laufraeder", "catLaufraeder"),
                      addLink("/reifen", "catWheels"),
                      addLink("/komplettgruppen", "catKomplettgruppe"),
                      addLink("/powermeter", "catPowermeter"),
                      addLink("/rahmen", "catFrame"),
                      addLink("/komponenten", "viewAll", classes.viewAllLink),
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={classes.spacingBottom}>
                  <FooterSegment
                    headline={t("catWearables")}
                    classNameHeadline={classes.categorySegment}
                    content={[
                      addLink("/smartwatches-fitness", "catSmartWatch"),
                      addLink("/herzfrequenzmesser", "catPuls"),
                      addLink("/activitytracker", "catActivity"),
                      addLink("/sportuhren", "catSportwatch"),
                      addLink("/smartphones", "catSmartphone"),
                      addLink("/wearables", "viewAll", classes.viewAllLink),
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={2} className={classes.spacingBottom}>
              <FooterSegment
                headline={t("topSubCategories")}
                content={[
                  addLink("/navigation-gps", "catGps"),
                  addLink("/fahrradcomputer", "catBikeComputer"),
                  addLink("/mtb-schuhe", "catMtbShoes"),
                  addLink("/kopf", "catHelmets"),
                  addLink("/reifen", "catReifen"),
                  addLink("/schaltwerke", "catSchaltwerk"),
                  addLink("/rollentrainer", "catRollentrainer"),
                  addLink("/indoor-cycling", "catIndoorCycling"),
                  addLink("/laufbaender", "catLaufbaender"),
                  addLink("/smartwatches-fitness", "catSmartwatchFitness"),
                  addLink("/drehmomentschluessel", "catDrehmomentschluessel"),
                  addLink("/montagestaender", "catMontagestaender"),
                ]}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.copyright} component="p">
              Copyright © 2024 The Cycleverse.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <div className={classes.priceDisclamer}>{t("footerPriceDisclamer")}</div>
    </footer>
  );
};

export default Footer;
