import Analytics from "../../../lib/analytics/analytics";
import { OnDisplayResultsFunction } from "./SearchBarTypes";
import React from "react";
import Searchbar from "./SearchBar";
import { TheCycleverseTheme } from "src/theme";
import { getHistory } from "src/lib/browseHistory";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  searchbar: {
    overflowX: "hidden",
  },
  searchbarContainer: {
    flexGrow: 1,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
      "& .search-bar": {
        maxWidth: 400,
        display: "inline-block",
      },
    },
    "& .MuiInputBase-input": {
      paddingLeft: theme.spacing(6),
    },
    "& .FX-recommendations-item": {
      // paddingLeft: theme.spacing(6)
    },
    "& .FX-recommendations": {
      textAlign: "left",
    },
  },
  searchbarSearch: {},
  searchDivider: theme.searchDivider,
  searchDividerSecondary: theme.searchDividerAlternate,
  iconButtonLeftContainer: {
    [theme.breakpoints.up("sm")]: {
      filter: "invert(100%)",
      position: "absolute",
    },
  },
  content: {
    display: "flex",
    paddingRight: theme.globalMarginMobile,
    paddingLeft: theme.globalMarginMobile,
    backgroundColor: theme.palette.background.secondary,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.globalMarginDesktop,
      paddingRight: theme.globalMarginDesktop,
    },
    [theme.breakpoints.down("xs")]: {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

type ProductSearchBarProps = {
  onDisplayResults: OnDisplayResultsFunction;
  className?: string;
  variant?: "secondary" | null;
  iconButtonLeft?: any;
  searchBarPlaceholderTextKey?: string;
  searchBarDividerVariant?: "secondary" | "primary";
  recommendationType: "products" | "articles";
};

const ProductSearchBar = (props: ProductSearchBarProps) => {
  const {
    className,
    variant,
    iconButtonLeft,
    searchBarPlaceholderTextKey,
    searchBarDividerVariant,
    onDisplayResults,
    recommendationType,
  } = props;
  const classes = useStyles();
  const { t, lang: locale } = useTranslation("common");
  const isDesktopView = useMediaQuery((theme: TheCycleverseTheme) => theme.breakpoints.up("sm"));
  const router = useRouter();
  const searchPath = recommendationType == "products" ? "/products/search" : "/blog/search";

  return (
    <div className={`${className ? className : ""} ${classes.searchbar}`} data-test="searchbar-default">
      <div className={classes.content}>
        {iconButtonLeft && <div className={classes.iconButtonLeftContainer}>{iconButtonLeft}</div>}
        <Searchbar
          recommendationType={recommendationType}
          containerClassName={classes.searchbarContainer}
          searchClassName={classes.searchbarSearch}
          placeholder={t(searchBarPlaceholderTextKey || "productSearchBarPlaceholder")}
          onSearchFocus={() => {
            Analytics.productSearchFocus();
          }}
          onSearch={(value) => {
            const dest = {
              pathname: searchPath,
              query: {
                s: value || "",
              },
            };
            router.push(dest, dest, { locale });
          }}
          onDisplayResults={onDisplayResults}
          variant={variant ? variant : isDesktopView ? "secondary" : "primary"}
        />
      </div>
      <div
        className={searchBarDividerVariant == "secondary" ? classes.searchDividerSecondary : classes.searchDivider}
      />
    </div>
  );
};

export default ProductSearchBar;
