import { LayoutSearchBar, LayoutVariant } from "./LayoutTypes";
import React, { FC, ReactElement, useContext } from "react";

import CookieConsent from "../CookieConsent/CookieConsent";
import CookieConsentCMP from "../CookieConsent/CookieConsentCMP";
import Footer from "../Navigation/Footer/Footer";
import Header from "../Navigation/Header";
import ScrollTopButton from "../Navigation/ScrollTopButton";
import Text from "src/components/Content/Text/Text";
import { TheCycleverseTheme } from "src/theme";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import HeaderHighlightContext from "./HeaderHighlightContext";
import { TextGobalKeys } from "types";
import Analytics from "src/lib/analytics/analytics";
import environment from "src/environment";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  content: {
    maxWidth: theme.gobalMaxContentWidth,
    margin: "auto",
    minHeight: "94vh",
  },
}));

export type LayoutProps = {
  variant?: LayoutVariant;
  searchBar?: LayoutSearchBar;
  menuComponent?: ReactElement | FC;
  contentClassName?: string;
  containerClassName?: string;
};

const MainLayout: FC<LayoutProps> = ({
  contentClassName,
  children,
  variant,
  searchBar,
  containerClassName,
  menuComponent,
}) => {
  const classes = useStyles();
  const headerHighlight = useContext(HeaderHighlightContext);
  const textKey: TextGobalKeys = "header-highlight";

  return (
    <>
      {environment.features.useNewConsent ? <CookieConsentCMP /> : <CookieConsent />}
      <div id="FX-MainLayout">
        <Header
          variant={variant}
          searchBar={searchBar}
          menuComponent={menuComponent}
          notificationComponent={
            <div
              data-test="headerHighlight"
              onClick={(e) => {
                if (e.target instanceof Element) {
                  const sourceElement = e.target;
                  const tag = sourceElement.tagName;
                  const link = sourceElement.getAttribute("href");
                  if (tag.toLocaleLowerCase() == "a") {
                    Analytics.headerHighlightClick(window.location.href, link);
                  }
                }
              }}
            >
              <Text
                id={textKey}
                prefetchedContent={headerHighlight}
                noFetch={true}
                buttonText="Header Notification bearbeiten"
              />
            </div>
          }
        />
        <div
          id="FX-MainLayout-Content"
          className={containerClassName ? containerClassName : clsx(classes.content, contentClassName)}
        >
          {children}
        </div>
        <ScrollTopButton />
        <Footer variant={variant} />
      </div>
    </>
  );
};

export default MainLayout;
