import React, { useEffect, FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import useTranslation from "next-translate/useTranslation";
import { TheCycleverseTheme } from "src/theme";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  messages: {
    backgroundColor: "#fff",
    borderRadius: theme.shape.borderRadius,
  },
  field: {
    "& input": {
      boxShadow: "inset 0px 0px 0px 0px",
      border: `1px solid ${theme.unflashyColor}`,
      minWidth: 100,
      width: "100%",
      padding: 8,
      paddingTop: 9,
      borderRadius: theme.shape.borderRadius,
      marginBottom: 5,
      marginRight: 5,
      fontSize: 16,
      fontWeight: 300,
    },
    "& input:focus": {
      outline: "none",
      "& input": {
        boxShadow: "inset 0px 0px 0px 0px",
        minWidth: 100,
        width: "100%",
        padding: 8,
        paddingTop: 9,
        borderRadius: theme.shape.borderRadius,
        marginBottom: 5,
        marginRight: 5,
        fontSize: 16,
        fontWeight: 300,
      },
    },
    "& button": {
      backgroundColor: theme.adviceColor,
      color: "#fff",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  },
  fieldCompact: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 5,
    "& input": {
      margin: 0,
      borderRadius: `${theme.shape.borderRadius}px 0px 0px ${theme.shape.borderRadius}px`,
      borderRight: 0,
    },
    "& button": {
      margin: 0,
      padding: "0px 10px",
      borderRadius: `0px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px`,
    },
  },
  buttonContainer: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
}));

type CustomFormProps = {
  status: string;
  message: string;
  onValidated: any;
  onSuccess: () => void;
  variant: string;
  submitButtonClass?: string;
  submitButtonText?: any;
  analytics?: any;
  productId?: string;
  openConfirmationModal?: () => void;
};

const CustomForm: FC<CustomFormProps> = ({
  status,
  message,
  onValidated,
  onSuccess,
  variant,
  analytics,
  productId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("newsletter");
  let email;
  useEffect(() => {
    if (status === "success") {
      onSuccess();
    }
  }, [status, onSuccess, analytics, email, productId]);

  const submit = (e) => {
    e.preventDefault();

    return (
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      })
    );
  };

  return (
    <form onSubmit={submit}>
      <div className={classes.messages}>
        {status === "sending" && (
          <div style={{ color: "blue", padding: 5, marginBottom: 10 }}>{t("landingpageInputSending")}</div>
        )}
        {status === "error" && (
          <div style={{ color: "red", padding: 5, marginBottom: 10 }} dangerouslySetInnerHTML={{ __html: message }} />
        )}
        {status === "success" && (
          <div style={{ color: "green", padding: 5, marginBottom: 10 }}>{t("landingpageInputFinish")}</div>
        )}
      </div>
      {variant == "compact" && (
        <div className={`${classes.field} ${classes.fieldCompact}`}>
          <input ref={(node) => (email = node)} type="email" placeholder={t("landingpageInputEmail")} />
          <Button type="submit">{t("landingpageInputSubmitCompact")}</Button>
        </div>
      )}
      {variant != "compact" && (
        <div className={classes.field}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={12} sm={12}>
              <input ref={(node) => (email = node)} type="email" placeholder={t("landingpageInputEmail")} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className={classes.buttonContainer} style={{ textAlign: "center" }}>
                <Button type="submit">{t("landingpageInputSubmit")}</Button>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </form>
  );
};

export default CustomForm;
