import { OnDisplayResultsFunction, OnSearchFocusFunction, OnSearchFunction } from "./SearchBarTypes";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useMount, useUnmount } from "react-use";

import Analytics from "src/lib/analytics/analytics";
import CircularProgress from "@material-ui/core/CircularProgress";
import ClearIcon from "@material-ui/icons/Clear";
import GqlClientContext from "../../../lib/gqlClientContext";
import InputBase from "@material-ui/core/InputBase";
import SearchBarRecommendationsArticle from "./SearchBarRecommendationsArticle";
import SearchBarRecommendationsProduct from "./SearchBarRecommendationsProduct";
import SearchBarRecommendationsTerm from "./SearchBarRecommendationsTerm";
import SearchIcon from "@material-ui/icons/Search";
import { TheCycleverseTheme } from "src/theme";
import { makeStyles } from "@material-ui/core/styles";
import { searchBarQuery } from "../../../graphql/queries";
import { useClickAway } from "react-use";
import { useMediaQuery } from "@material-ui/core";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.secondary,
    width: "100%",
  },
  searchSecondary: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    width: "100%",
    maxWidth: "1000px",
  },
  searchSecondaryWithoutData: {
    backgroundColor: theme.palette.background.default,
  },
  searchIcon: {
    width: theme.spacing(4),
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  clearIcon: {
    width: theme.spacing(4),
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
  },
  inputRoot: {
    color: "inherit",
    display: "flex",
    flex: 1,
  },
  inputInput: {
    transition: theme.transitions.create("width"),
    padding: "8px 8px 8px 56px",
    width: "100%",
    "&::-webkit-search-decoration": {
      "-webkit-appearance": "none",
    },
    "&::-webkit-search-cancel-button": {
      "-webkit-appearance": "none",
    },
    "&::-webkit-search-results-button": {
      "-webkit-appearance": "none",
    },
    "&::-webkit-search-results-decoration": {
      "-webkit-appearance": "none",
    },
  },
  subTextContainer: {
    textAlign: "center",
    marginLeft: 20,
    marginTop: 5,
  },
  recommendationContainer: {
    width: "100%",
    paddingTop: "4px",
    [theme.breakpoints.up("md")]: {
      width: "15%",
    },
  },
  articles: {
    width: "100%",
    borderTop: `1px solid ${theme.unflashyColor}`,
    borderLeft: "none",
    paddingTop: "4px",
    [theme.breakpoints.up("md")]: {
      width: "30%",
      borderTop: "none",
      borderLeft: `1px solid ${theme.unflashyColor}`,
    },
  },
  productsCards: {
    width: "100%",
    borderColor: theme.unflashyColor,
    borderTop: `1px solid ${theme.unflashyColor}`,
    borderLeft: "none",
    paddingTop: "4px",
    [theme.breakpoints.up("md")]: {
      width: "55%",
      borderLeft: `1px solid ${theme.unflashyColor}`,
      borderTop: "none",
    },
  },
  searchbarContainerWidthData: {
    flexGrow: 1,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: "90%",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      textAlign: "center",
      "& .search-bar": {
        maxWidth: "90%",
        display: "inline-block",
      },
    },
    "& .MuiInputBase-input": {
      paddingLeft: theme.spacing(4),
    },
    "& .FX-recommendations-item": {
      paddingLeft: 0,
      paddingTop: 5,
      paddingBottom: 5,
    },
    "& .FX-recommendations": {
      textAlign: "left",
      [theme.breakpoints.up("sm")]: {
        margin: "0px 20px",
      },
    },
  },
  formContainer: {
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    flex: 1,
    width: "90%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  formDivider: {
    borderBottomColor: theme.unflashyColor,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    width: "90%",
    [theme.breakpoints.up("sm")]: {
      width: "400px",
    },
  },
  formDividerSecondary: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "4px",
    borderBottomWidth: 0,
    [theme.breakpoints.down("sm")]: {
      borderBottomStyle: "solid",
      backgroundColor: theme.palette.background.secondary,
      borderBottomWidth: 1,
      borderRadius: "0px",
    },
  },
  searchedItemsContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "0px 20px",
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(4),
      flexDirection: "row",
      margin: "20px 20px",
    },
  },
  formStyles: {
    display: "flex",
    flex: 1,
  },
}));

type SearchbarType = {
  onSearch?: OnSearchFunction;
  onSearchFocus?: OnSearchFocusFunction;
  onDisplayResults?: OnDisplayResultsFunction;
  placeholder?: string;
  containerClassName?: string;
  variant?: "primary" | "secondary" | null;
  searchClassName?: string;
  subText?: any;
  searchType?: "highlight" | null;
  scrollTo?: "input" | "top";
  recommendationType?: "products" | "articles";
};

const Searchbar = (props: SearchbarType) => {
  const classes = useStyles();
  const {
    onSearch,
    onSearchFocus,
    onDisplayResults,
    placeholder,
    containerClassName,
    variant,
    searchClassName,
    subText,
    searchType,
    scrollTo,
    recommendationType,
  } = props;
  const router = useRouter();
  const { s } = router.query;
  const initialValueSearchString: string = s ? (s as string) : "";
  const [searchString, setSearchString] = useState(initialValueSearchString);
  const [searchTimer, setSearchTimer] = useState(null);
  const [analyticsTimer, setAnalyticsTimer] = useState(null);
  const [searchedData, setSearchedData] = useState(null);
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [displayResults, setDisplayResults] = useState(false);
  const isDesktopView = useMediaQuery((theme: TheCycleverseTheme) => theme.breakpoints.up("sm"));
  const isScreenSoSmall = useMediaQuery("(max-height: 800px)");
  const { lang } = useTranslation("common");
  const gqlFetcher = useContext(GqlClientContext);
  const mainRef = useRef(null);
  const inputRef = useRef(null);
  var isMounted = true;

  useEffect(() => {
    setSearchString(s ? (s as string) : "");
  }, [s]);

  useUnmount(() => {
    isMounted = false;
    clearTimer();
  });
  useMount(() => {
    isMounted = true;
  });

  const clearTimer = () => {
    if (searchTimer != null) {
      clearTimeout(searchTimer);
      setSearchTimer(null);
    }
  };

  const close = () => {
    setDisplayResults(false);
    onDisplayResults(false);
    setSearchInProgress(false);
  };

  const clear = () => {
    close();
    setSearchedData(null);
    setSearchString("");
    onSearch("");
  };

  const focus = () => {
    if (searchString) {
      updateSearch(searchString);
    }
    onSearchFocus && onSearchFocus();
  };

  const scrollToResult = () => {
    if (scrollTo && scrollTo == "input") {
      scrollInputIntoView();
    } else {
      scrollToTop();
    }
  };

  const scrollInputIntoView = () => {
    setTimeout(() => {
      var element = inputRef?.current;
      if (element) {
        var headerOffset = 20;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }, 100);
  };

  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top: 0,
      });
    }, 100);
  };

  const triggerAnalytics = (term, productCount, articleCount, termCount) => {
    const isFirst = !analyticsTimer;
    const waitTime = isFirst ? 300 : 6000;
    if (analyticsTimer) {
      clearTimeout(analyticsTimer);
    }
    setAnalyticsTimer(
      setTimeout(async () => {
        Analytics.searchbarSearch(term, productCount, articleCount, termCount);
        !isFirst && setAnalyticsTimer(null);
      }, waitTime)
    );
  };

  const updateSearch = (value) => {
    scrollToResult();
    onDisplayResults(true);
    setSearchString(value);
    setSearchInProgress(true);
    clearTimer();
    setSearchTimer(
      setTimeout(async () => {
        if (!value || value == "") {
          clear();
        } else {
          const searchResponse = await gqlFetcher([
            searchBarQuery,
            JSON.stringify({
              sizeProducts: isDesktopView ? 8 : isScreenSoSmall ? 4 : 4,
              sizeArticles: isDesktopView ? 4 : isScreenSoSmall ? 4 : 4,
              searchString: value,
            }),
          ]);
          if (isMounted) {
            setSearchedData(searchResponse);
            setDisplayResults(true);
            const productCount = searchResponse?.queryProducts?.items?.length || 0;
            const articleCount = searchResponse?.queryArticles?.items?.length || 0;
            const termCount = getRecommendedTerms(recommendationType, searchResponse).length;
            triggerAnalytics(value, productCount, articleCount, termCount);
          }
        }
        setSearchInProgress(false);
      }, 300)
    );
  };

  const valueSelected = (value) => {
    onDisplayResults(false);
    setSearchString(value);
    setSearchedData(null);
    onSearch(value);
  };

  const catchEnter = (e) => {
    if (e.key === "Enter") {
      valueSelected(e.target.value);
      clearTimer();
      setSearchInProgress(false);
    }
  };

  useClickAway(mainRef, () => {
    close();
  });

  const getRecommendedTerms = (recommendationType, searchedData) => {
    return (
      (recommendationType == "articles"
        ? searchedData?.searchRecommendation?.recommendedTermsArticles
        : searchedData?.searchRecommendation?.recommendedTermsProducts) || []
    );
  };

  return (
    <div
      ref={mainRef}
      className={`${!(searchString && searchedData) ? containerClassName : classes.searchbarContainerWidthData}`}
    >
      <div
        className={`
          ${
            variant === "secondary"
              ? `${classes.searchSecondary} ${
                  !(
                    searchString &&
                    (searchedData?.searchRecommendation ||
                      searchedData?.queryArticles?.items?.length ||
                      searchedData?.queryProducts?.terms?.length)
                  )
                    ? classes.searchSecondaryWithoutData
                    : ""
                }`
              : classes.search
          } 
          ${searchClassName}
          search-bar
          `}
      >
        <div
          className={`${classes.formContainer} ${
            searchString && searchedData
              ? `${classes.formDivider} ${searchType !== "highlight" ? classes.formDividerSecondary : ""}`
              : ""
          }`}
        >
          <div className={classes.searchIcon}>
            {searchInProgress ? (
              <CircularProgress color="secondary" style={{ width: 20, height: 20 }} />
            ) : (
              <SearchIcon />
            )}
          </div>
          <form
            action={`/${lang}/products/search?s=${searchString}`}
            method="get"
            onSubmit={(e) => {
              e.preventDefault();
              return false;
            }}
            className={classes.formStyles}
          >
            <InputBase
              type="search"
              ref={inputRef}
              placeholder={placeholder}
              onFocus={(e) => focus()}
              onChange={(e) => updateSearch(e.target.value)}
              onKeyPress={(e) => catchEnter(e)}
              value={searchString}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
              data-test="searchbar-input"
            />
          </form>
          {searchString ? (
            <div className={classes.clearIcon}>
              <ClearIcon
                data-test="clearSearchBarInput"
                onClick={() => {
                  clear();
                }}
              />
            </div>
          ) : (
            <div />
          )}
        </div>
        {displayResults &&
          searchString &&
          (searchedData?.searchRecommendation ||
            searchedData?.queryArticles?.items?.length ||
            searchedData?.queryProducts?.terms?.length) && (
            <div className={classes?.searchedItemsContainer} id="searchedItemsContainer" data-test="searchbarResults">
              <div className={classes.recommendationContainer}>
                <SearchBarRecommendationsTerm
                  recommendedTerms={getRecommendedTerms(recommendationType, searchedData)}
                  onSelect={(value) => {
                    valueSelected(value);
                  }}
                  searchValue={searchString}
                />
              </div>
              <div className={classes.articles}>
                <SearchBarRecommendationsArticle
                  articles={(searchString && searchedData?.queryArticles?.items) || []}
                  searchValue={searchString}
                  onSearchMore={close}
                />
              </div>
              <div className={`${classes.productsCards}`}>
                <SearchBarRecommendationsProduct
                  products={(searchString && searchedData?.queryProducts?.items) || []}
                  searchValue={searchString}
                  onSearchMore={close}
                />
              </div>
            </div>
          )}
      </div>
      {subText && <div className={classes.subTextContainer}>{subText}</div>}
    </div>
  );
};

export default Searchbar;
