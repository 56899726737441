import React, { FC, ReactNode, useState } from "react";

import Analytics from "../../lib/analytics/analytics";
import { Button } from "@material-ui/core";
import CustomForm from "./CustomForm";
import Grid from "@material-ui/core/Grid";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { TheCycleverseTheme } from "src/theme";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import useTranslation from "next-translate/useTranslation";

const useStyles = makeStyles((theme: TheCycleverseTheme) => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    // background: "rgb(86,64,138)",
    background: "linear-gradient(85deg, rgba(86,64,138,1) 0%, rgba(101,76,160,1) 9%, rgba(191,25,141,1) 100%)",
    color: theme.palette.primary.main,
  },
  rootModal: {
    background: "none",
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: theme.palette.background.secondary,
    color: theme.palette.secondary.main,
  },
  modalCustomForm: {
    paddingTop: theme.spacing(1),
  },
  contentContainer: {
    paddingBottom: 0,
    paddingTop: (props: SubscribeFormProps) => (props.formVariant == "compact" ? theme.spacing(2) : theme.spacing(4)),
    paddingLeft: (props: SubscribeFormProps) => (props.formVariant == "compact" ? theme.spacing(2) : theme.spacing(4)),
    paddingRight: (props: SubscribeFormProps) => (props.formVariant == "compact" ? theme.spacing(2) : theme.spacing(4)),
  },
  contentContainerFinish: {
    paddingBottom: theme.spacing(2),
  },
  subscribeContainer: {
    paddingBottom: (props: SubscribeFormProps) =>
      props.formVariant == "compact" ? theme.spacing(2) : theme.spacing(4),
    paddingTop: (props: SubscribeFormProps) => (props.formVariant == "compact" ? theme.spacing(1) : theme.spacing(2)),
    paddingLeft: (props: SubscribeFormProps) => (props.formVariant == "compact" ? theme.spacing(2) : theme.spacing(4)),
    paddingRight: (props: SubscribeFormProps) => (props.formVariant == "compact" ? theme.spacing(2) : theme.spacing(4)),
  },
  notice: {
    fontSize: 12,
    padding: 4,
    "& b": {
      fontWeight: 500,
    },
  },
  finishText: {
    fontSize: 24,
    fontFamily: theme.highlightFontFamily,
    fontWeight: 600,
    lineHeight: "32px",
    paddingBottom: theme.spacing(4),
  },
  finishHeadline: {
    color: theme.saleColor,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 900,
    fontSize: 60,
    lineHeight: "80px",
  },
  finishButton: {
    textAlign: "center",
    paddingBottom: theme.spacing(1),
    "& button": {
      backgroundColor: theme.adviceColor,
      color: "#fff",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  },
  finishHeadlineCompact: {
    color: theme.positiveColor,
    fontWeight: 900,
    fontSize: 22,
  },
  finishSubHeadlineCompact: {
    color: theme.positiveColor,
  },
  finishTextCompact: {
    color: theme.positiveAlternateColor,
  },
}));

export type SubscribeFormProps = {
  afterSubscribe: () => void;
  close: () => void;
  mailchimpUrl: string;
  className?: string;
  ctaElement?: any;
  formVariant?: "compact" | "default";
  page: string;
  showPrivacyNote: boolean;
};

const SubscribeForm: FC<SubscribeFormProps> = (props) => {
  const { afterSubscribe, mailchimpUrl, className, ctaElement, close, page, formVariant, showPrivacyNote } = props;
  const classes = useStyles(props);
  const { t } = useTranslation("newsletter");
  const [isSubscribed, setSubscribed] = useState(false);

  const onSuccess = () => {
    Analytics.newsletterSubscribe(page);
    setSubscribed(true);
    afterSubscribe();
  };

  return (
    <div className={`${classes.root} ${classes.rootModal} ${className ? className : ""}`}>
      <Grid container className={`FX-SubscribeForm-Container ${classes.contentContainer}`}>
        <Grid item>
          {!isSubscribed && props.children}
          {isSubscribed && (
            <div className={classes.contentContainerFinish}>
              {formVariant == "compact" && (
                <>
                  <div className={classes.finishHeadlineCompact}>{t("finishHeadlineCompact")}</div>
                  <div className={classes.finishTextCompact}>{t("finishTextCompact")}</div>
                </>
              )}
              {formVariant != "compact" && (
                <>
                  <div className={classes.finishHeadline}>{t("finishHeadline")}</div>
                  <div className={classes.finishText}>{t("finishSubHeadline")}</div>
                  <div className={classes.finishText}>{t("finishText")}</div>
                  <div className={classes.finishButton}>
                    <Button onClick={() => close()}>{t("finishButton")}</Button>
                  </div>
                </>
              )}
            </div>
          )}
        </Grid>
      </Grid>

      {!isSubscribed && (
        <Grid container className={classes.subscribeContainer}>
          {ctaElement && ctaElement}
          <Grid item xs={12} className={clsx(classes.modalCustomForm, "FX-SubscribeForm-Form")}>
            <MailchimpSubscribe
              url={mailchimpUrl}
              render={({ subscribe, status, message }) => {
                return (
                  <CustomForm
                    status={status}
                    message={message}
                    onValidated={(formData) => {
                      subscribe(formData);
                    }}
                    onSuccess={() => onSuccess()}
                    variant={formVariant}
                  />
                );
              }}
            />
            {showPrivacyNote && (
              <div className={classes.notice}>
                <b>{t("privacyNotice1")}</b>
                {t("privacyNotice2")}
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default SubscribeForm;
